import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";

const BASE_URL = process.env.REACT_APP_BASEURL;
const isLive = process.env.REACT_APP_LIVE;
interface Login {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
  user: {
    managerDepartment: string;
    managerName: string;
    staffName: string;
    displayName: string;
    email: string;
    department: string;
    groups: string;
    mobileNo: string;
    staffId: string;
    role: string;
    response: string;
    responseMessage: string;
  };
}
interface DataResponse {
  data: Login | {};
}

interface AuthResponse {
  data: DataResponse | {};
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const password = (getState() as RootState).StaffDataReducer?.password;
      const encryptedPassword = (getState() as RootState).StaffDataReducer
        ?.encryptedPassword;

      const pass = isLive === "true" ? encryptedPassword : password;

      headers.set("Content-Type", "application/json");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("Access-Control-Allow-Headers", "Content-Type");
      headers.set("Access-Control-Allow-Methods", "OPTIONS,POST,GET,PATCH");
      headers.set("password", pass);
    },
  }),

  endpoints: (builder) => ({
    //login
    login: builder.mutation<AuthResponse, { username: string; token: string }>({
      query: (body) => ({
        url: "/user/login",
        method: "POST",
        body,
      }),
    }),

    // get reset token
    getToken: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/user/generate-reset-token?username=${username}`,
        method: "GET",
      }),
    }),

    //login
    resetUser: builder.mutation<any, { username: string; token: string }>({
      query: (body) => ({
        url: "/user/reset-user",
        method: "POST",
        body,
      }),
    }),

    //logout all
    logoutAll: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/user/logout-all?username=${username}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetTokenMutation,
  useResetUserMutation,
  useLogoutAllMutation,
} = authApi;
