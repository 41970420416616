import React, { useEffect, useState } from "react";
import {
  Bar,
  BoldText,
  Container,
  Greet,
  GreetContainer,
  ImageContainer,
  Paragraph,
  TextContainer,
  Time,
  TimeContainer,
} from "./style";
import { ReactComponent as Avatar } from "../../assets/svg/avatar.svg";
import { ReactComponent as Clock } from "../../assets/svg/bigclock.svg";

const Overview = () => {
  const staffInfoJSON = localStorage.getItem("staffInfo");

  const localInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};

  const date = new Date();
  const [dateState, setDateState] = useState(date);
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  const Greeting = () => {
    let hours = date.getHours();
    let greet;

    if (hours < 12) {
      greet = "Morning";
    } else if (hours >= 12 && hours <= 16) {
      greet = "Afternoon";
    } else if (hours >= 16 && hours <= 24) {
      greet = "Evening";
    }
    return greet;
  };

  const getTag = () => {
    let hours = date.getHours();
    let tag;

    if (hours < 12) {
      tag = "AM";
    } else if (hours >= 12 && hours <= 24) {
      tag = "PM";
    }
    return tag;
  };
  return (
    <Container>
      <ImageContainer>
        <Bar></Bar>
        <Avatar />
      </ImageContainer>
      <TextContainer>
        <TimeContainer>
          <Clock />
          <Time>
            {dateState.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            })}{" "}
            {getTag()}
          </Time>
        </TimeContainer>
        <GreetContainer>
          <Greet>Good {Greeting()}</Greet>
          <BoldText>{localInfo?.user?.displayName}</BoldText>
        </GreetContainer>
        <Paragraph>
          Start your day right by navigating through NIPS admin portal for your
          work. Have a productive day
        </Paragraph>
      </TextContainer>
    </Container>
  );
};

export default Overview;
