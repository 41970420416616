import { useEffect, useState } from "react";
import ContentLayout from "../../layout/Content";
import Header from "../../components/Header/Header";
import {
  Container,
  HeaderText,
  BottomContainer,
  TableContainer,
  DataDiv,
} from "./style";
import { FaGithubAlt } from "react-icons/fa";
import PrimaryTable from "../../components/Table";
import PrimaryButton from "../../components/Buttons/Button";
import {
  useApproveUserMutation,
  usePendingUserApprovalMutation,
} from "../../services/adminService";
import toast from "react-hot-toast";
import PuffLoader from "../../components/Loader";
import { BoldText, Main } from "../ApproveLimit/style";

const ApproveUser = () => {
  const [pendingList, setpendingList] = useState<any>([]);
  const [username, setUsername] = useState<string>("");
  const [pendingUser, pendingUserMutation] = usePendingUserApprovalMutation();
  const [approveUser, approveUserMutation] = useApproveUserMutation();

  const handleGetPendingList = async () => {
    try {
      const response = await pendingUser().unwrap();
      if (response.statusCode === 200) {
        toast.success(response?.message);
        setpendingList(response?.data);
      }
    } catch (err: any) {
      // toast.error(err?.data?.Message);
    }
  };
  useEffect(() => {
    handleGetPendingList();
  }, []);

  const handleAction = async (username: string) => {
    setUsername(username);
  };

  const handleApprove = async () => {
    try {
      const requiredData = {
        username: username,
        isApproved: true,
      };
      const response = await approveUser(requiredData).unwrap();

      if (response.statusCode === 200) {
        toast.success(response?.message);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.Message);
    }
  };

  const handleDecline = async () => {
    try {
      const requiredData = {
        username: username,
        isApproved: false,
      };
      const response = await approveUser(requiredData).unwrap();

      if (response.statusCode === 200) {
        toast.success(response?.message);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.Message);
    }
  };

  const userActionList = [
    {
      title: "Approve",
      action: handleApprove,
    },
    {
      title: "Decline",
      action: handleDecline,
    },
  ];

  const header = [
    "S/N",
    "UserName",
    "Role Name",
    "Status",
    "Created By",
    "Last Seen",
    "Action",
  ];

  const dataBody = pendingList?.map((item: any, index: number) => [
    index + 1,
    <DataDiv>{item?.username?.slice(0, item.username?.indexOf("@"))}</DataDiv>,

    item?.role,
    item?.createdby?.slice(0, item?.createdby.indexOf("@")),
    item?.createdon?.slice(0, 10),
    item?.lastlogin?.slice(0, 10),
    <>
      <PrimaryButton
        key={item.username}
        background={"#fff"}
        color={"#5c2684"}
        width={"100%"}
        title={"Action"}
        icon={""}
        rightIcon={true}
        act={true}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={handleAction}
        leftIcon={false}
        border={"1px solid #5c2684"}
        action={true}
        options={userActionList}
        item={item}
      />
    </>,
  ]);

  return (
    <>
      <Header title="User Approval" />
      <ContentLayout>
        <Container>
          <BottomContainer>
            {dataBody.length > 1 && <HeaderText>Users</HeaderText>}
          </BottomContainer>
          {/* )} */}
          <TableContainer>
            {pendingUserMutation?.isLoading ? (
              <PuffLoader />
            ) : (
              <>
                {dataBody.length > 1 ? (
                  <PrimaryTable header={header} body={dataBody} />
                ) : (
                  <Main>
                    {" "}
                    <FaGithubAlt size={90} color="#5C2684" />
                    <BoldText>No record found!</BoldText>
                  </Main>
                )}
              </>
            )}
          </TableContainer>
        </Container>
      </ContentLayout>
    </>
  );
};

export default ApproveUser;
