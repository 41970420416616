import { QuickComponentType } from "../../types/Types";
import {
  CardComponent,
  CardContents,
  CardTexts,
  Wrapper,
  Title,
  CardContainer,
} from "./CardStyles";
import { ReactComponent as Exemption } from "../../assets/svg/exemption.svg";
import { ReactComponent as Approve } from "../../assets/svg/approve.svg";
import { ReactComponent as Inward } from "../../assets/svg/inward.svg";
import { ReactComponent as Outward } from "../../assets/svg/outward.svg";
import { ReactComponent as Finacle } from "../../assets/svg/finacle.svg";
import { ReactComponent as Bill } from "../../assets/svg/bill.svg";
import { useNavigate } from "react-router-dom";

const adminData: QuickComponentType[] = [
  {
    title: "Manage Profile",
    action: "manage-profile",
    img: Exemption,
  },
  // {
  //   title: "Inward Report",
  //   action: "reports/inward",
  //   img: Inward,
  // },
  // {
  //   title: "Outward Report",
  //   action: "reports/outward",
  //   img: Outward,
  // },
  // {
  //   title: "Finacle Spool",
  //   action: "reports/finacle",
  //   img: Finacle,
  // },
  // {
  //   title: "E-bills Incoming Posting",
  //   action: "reports/finacle",
  //   img: Bill,
  // },
  // {
  //   title: "Transactions",
  //   action: "/trans",
  //   img: Bill,
  // },
];

const checkerData: QuickComponentType[] = [
  {
    title: "Approve Limit",
    action: "approval/limit",
    img: Approve,
  },
  {
    title: "Inward Report",
    action: "reports/inward",
    img: Inward,
  },
  {
    title: "Outward Report",
    action: "reports/outward",
    img: Outward,
  },
  {
    title: "Finacle Spool",
    action: "reports/finacle",
    img: Finacle,
  },
  {
    title: "Transactions",
    action: "trans",
    img: Bill,
  },
  {
    title: "E-bills Incoming Posting",
    action: "reports/finacle",
    img: Bill,
  },
];

const makerData: QuickComponentType[] = [
  {
    title: "Limit Exemption",
    action: "limit-exemption",
    img: Approve,
  },
  {
    title: "Inward Report",
    action: "reports/inward",
    img: Inward,
  },
  {
    title: "Outward Report",
    action: "reports/outward",
    img: Outward,
  },
  {
    title: "Finacle Spool",
    action: "reports/finacle",
    img: Finacle,
  },
  {
    title: "E-bills Incoming Posting",
    action: "reports/finacle",
    img: Bill,
  },
  {
    title: "Transactions",
    action: "trans",
    img: Bill,
  },
];
const QuickNavCard = () => {
  const navigate = useNavigate();

  const staffInfoJSON = localStorage.getItem("staffInfo");

  const localInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};

  return (
    <Wrapper>
      <Title>Quick Navigations</Title>
      <CardContainer>
        {localInfo?.user?.role?.toLowerCase() === "admin" && (
          <>
            {adminData.map((data) => (
              <CardComponent
                key={data.title}
                onClick={() => navigate(`${data.action}`)}
              >
                <CardContents>
                  <data.img />
                  <CardTexts>{data.title}</CardTexts>
                </CardContents>
              </CardComponent>
            ))}
          </>
        )}
        {localInfo?.user?.role?.toLowerCase() === "checker" && (
          <>
            {checkerData.map((data) => (
              <CardComponent
                key={data.title}
                onClick={() => navigate(`${data.action}`)}
              >
                <CardContents>
                  <data.img />
                  <CardTexts>{data.title}</CardTexts>
                </CardContents>
              </CardComponent>
            ))}
          </>
        )}
        {localInfo?.user?.role?.toLowerCase() === "maker" && (
          <>
            {makerData.map((data) => (
              <CardComponent
                key={data.title}
                onClick={() => navigate(`${data.action}`)}
              >
                <CardContents>
                  <data.img />
                  <CardTexts>{data.title}</CardTexts>
                </CardContents>
              </CardComponent>
            ))}
          </>
        )}
      </CardContainer>
    </Wrapper>
  );
};

export default QuickNavCard;
