import styled from "styled-components";

interface DateP {
  border: string;
}
interface DwrapProps {
  opacity: any;
}
export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  display: none;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;
export const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
`;

export const Wrapper = styled.div<DwrapProps>`
  position: relative;
  width: 100%;

  opacity: ${(props) => props.opacity};
  border-radius: 8px;
  ._1aDvk {
    background-color: #5c2684 !important;
  }
  ._yof3Q select {
    border-radius: 2.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }
  ._24OKz button {
    color: #5c2684;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }
`;

export const TransparentBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

export const CalendarWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 90%;
  left: 70px;
  width: 100%;

  @media screen and (max-width: 500px) {
    left: 0;
    top: 110%;
  }

  .calendar {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      max-width: 100%;
      border: solid red;
    }
  }
`;

export const Iconwrapper = styled.div`
  margin-right: 20px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    margin-right: 18px;
  }
`;

export const Input = styled.input`
  color: #4e5152;
  height: 48px;
  width: 90%;
  border: none;
  background: none;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  color: ${({ disabled }) => (disabled ? "#aca09f" : "")};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    font-size: 14px;
    opacity: 0.7;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const InputWrapper = styled.div<DateP>`
  align-items: center;
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  border: ${(props) => props.border};
  padding: 8px 24px;
  height: 50px;
  transition: 0.3s all ease;

  &:focus {
    outline: none;
    border-color: yellow;
  }

  @media screen and (max-width: 600px) {
    height: 48px;
  }
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: clamp(12px, 1.3vw, 14px);
`;

export const ErrMsg = styled.div`
  position: relative;
  color: red;
  font-size: 12px;
  justify-content: center;
  align-items: center;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
