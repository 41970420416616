import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 16px;
  gap: 95px;
  width: 100%;
  padding: 16px 14px 0px 14px;
`;

export const ImageContainer = styled.div`
  display: flex;
  gap: 70px;
`;
export const Bar = styled.div`
  display: flex;
  height: 320px;
  width: 3.5%;
  border-radius: 16px;
  background-color: #5c2684;
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const TimeContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const Time = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  color: #5c2684;
`;

export const GreetContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Greet = styled.p`
  font-weight: 400;
  font-size: 38px;
  line-height: 120%;
  color: #2d2235;
`;

export const BoldText = styled.p`
  font-weight: 700;
  font-size: 38px;
  line-height: 120%;
  color: #2d2235;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  width: 60%;
  line-height: 120%;
  color: #2d2235;
`;
export const BtnHolder = styled.div`
  display: flex;
`;
