import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ProtectedProps {
  isVerified: any;
  children: any;
}

const Protected = ({ isVerified, children }: ProtectedProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isVerified?.accessToken) {
      navigate("/login");
    }
  }, [isVerified, navigate]);

  if (isVerified) {
    return children;
  }
};

export default Protected;
