import React, { useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons/lib";
import {
  AdminSidebarData,
  CheckerSidebarData,
  MakerSidebarData,
  SuperUserSidebarData,
} from "./data";
import { ReactComponent as FCMBIcon } from "../../assets/svg/FC.svg";
import { useLogoutMutation } from "../../services/limitService";
import { store } from "../../store/store";
import { ReactComponent as LogoutIcon } from "../../assets/svg/logout.svg";
import toast from "react-hot-toast";
import SubMenu from "./subMenu";
import {
  MainContainer,
  ListWrapper,
  LogoWrapper,
  SidebarNav,
  SidebarWrap,
  SidebarParentWrapper,
  LogoutWrapper,
  IconButtons,
  ButtonTexts,
} from "./style";
import { handleLogout } from "../../utils/logout";

const Sidebar = () => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const staffInfoJSON = localStorage.getItem("staffInfo");

  const localInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};
  let staff: { accessToken: string } = JSON.parse(
    localStorage.getItem("staffInfo") || "{}"
  );
  let accessToken = staff.accessToken;

  var options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const memoizedIconContextValue = useMemo(() => ({ className: "icons" }), []);

  return (
    <MainContainer>
      <IconContext.Provider value={memoizedIconContextValue}>
        <LogoWrapper>
          <FCMBIcon />
        </LogoWrapper>

        <SidebarNav>
          <SidebarWrap>
            {localInfo?.user?.role?.toLowerCase() === "admin" && (
              <>
                {AdminSidebarData.map((item) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {localInfo?.user?.role?.toLowerCase() === "checker" && (
              <>
                {CheckerSidebarData.map((item) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {localInfo?.user?.role?.toLowerCase() === "maker" && (
              <>
                {MakerSidebarData.map((item) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {localInfo?.user?.role?.toLowerCase() === "superuser" && (
              <>
                {SuperUserSidebarData.map((item) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            <SidebarParentWrapper>
              <LogoutWrapper onClick={() => handleLogout(logout, navigate)}>
                <IconButtons>
                  <LogoutIcon />
                  <ButtonTexts color={"black"}>Logout</ButtonTexts>
                </IconButtons>
              </LogoutWrapper>
            </SidebarParentWrapper>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </MainContainer>
  );
};

export default Sidebar;
