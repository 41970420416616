import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: #2d2235;
`;
export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const CardComponent = styled.div`
  background: white;
  width: 32%;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 24px;
`;
export const CardContents = styled.div`
  padding: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
export const Bold = styled.b`
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
`;
export const CardTexts = styled.div`
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-top: 34px;
`;
