import React from "react";
import { LayoutRouteProps } from "react-router-dom";
import { ContentWrapper } from "./style";

const ContentLayout = ({ children }: LayoutRouteProps) => {
  return (
    <>
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};

export default ContentLayout;
