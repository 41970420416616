import React, { ChangeEventHandler } from "react";
import { CheckWrap, ErrorMessage, Input, InputContainer, Label } from "./style";

interface TextProps {
  label: string;
  error: any;
  name: string;
  padding: number;
  placeholder: string;
  text: string;
  register?: any;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}
const TextArea: React.FC<TextProps> = ({
  label,
  register,
  name,
  padding,
  placeholder,
  error,
  value,
  onChange,
}) => {
  return (
    <CheckWrap>
      <Label>{label}</Label>
      <InputContainer>
        {register ? (
          <Input
            name={name}
            padding={padding}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            {...register(name)}
          />
        ) : (
          <Input name={name} onChange={(e)=>{
            console.log("e ::>>", e)
            }} padding={padding} placeholder={placeholder} />
        )}
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </CheckWrap>
  );
};

export default TextArea;



