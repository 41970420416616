import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Layout from "../layout/DashboardLayout/Layout";
import Approve from "../pages/ApproveLimit/Approve";
import Finacle from "../pages/Finacle/Finacle";
import Home from "../pages/Home/Home";
import Inward from "../pages/InwardRequest/Inward";
import LimitExemption from "../pages/LimitExemption";
import EditLimit from "../pages/EditLimit";
import ProfileManagement from "../pages/ManageProfile/assignRole";
import Outward from "../pages/OutwardRequest/Outward";
import Tran from "../pages/Trans/Transa";
import Protected from "./Protected";
import ApproveUser from "../pages/ApproveUser";
import Switch from "../pages/SwitchApproval";
import BankSwitch from "../pages/BankSwitch";
import BankSwitchReport from "../pages/BankSwitchReport";

const Login = lazy(() => import("../pages/Auth/Login/login"));

const AppRouter = () => {
  // const staffInfoJSON = localStorage.getItem("staffInfo");

  // const loggedIn = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};

  const staffInfo = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo
  );

  return (
    <Suspense>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <Protected isVerified={staffInfo}>
              {" "}
              <Layout children={<></>} title={""} />{" "}
            </Protected>
          }
        >
          <Route index element={<Home />} />
          <Route path="limit-exemption" element={<LimitExemption />} />
          <Route path="edit-limit" element={<EditLimit />} />
          <Route path="approval">
            <Route index element={<Approve />} />
            <Route path="limit" element={<Approve />} />
            <Route path="switch" element={<Switch />} />
          </Route>

          <Route path="bank" element={<BankSwitch />} />
          <Route path="reports">
            <Route index element={<Inward />} />
            <Route path="inward" element={<Inward />} />
            <Route path="outward" element={<Outward />} />
            <Route path="bank-switch" element={<BankSwitchReport />} />
            <Route path="finacle" element={<Finacle />} />
          </Route>
          <Route path="trans" element={<Tran />} />
          <Route path="manage-profile" element={<ProfileManagement />} />
          <Route path="approve-user" element={<ApproveUser />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
