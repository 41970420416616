import { LayoutProps } from "../../types/Types";
import { Outlet } from "react-router-dom";
import { Body, BodyLeft, BodyRight, Dashboard } from "./style";
import StaffSidebar from "../../components/StaffSidebar";

const Layout = ({ children }: LayoutProps) => {
  return (
    <Dashboard>
      <Body>
        <BodyLeft>
          <StaffSidebar />
        </BodyLeft>
        <BodyRight>
          <Outlet />
          {children}
        </BodyRight>
      </Body>
    </Dashboard>
  );
};

export default Layout;
