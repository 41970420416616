import {
  SidebarButtonType,
  TableProps,
  NeftTableProps,
  ReportTableProps,
  UMTableProps,
  UploadNeftType,
  BatchTableProps,
} from "../types/Types";

import { ReactComponent as HomeIcon } from "../assets/svg/home.svg";
import { ReactComponent as Home2Icon } from "../assets/svg/home2.svg";
import { ReactComponent as TransactIcon } from "../assets/svg/transact.svg";
import { ReactComponent as Transact2Icon } from "../assets/svg/transact2.svg";
import { ReactComponent as ReportIcon } from "../assets/svg/report.svg";
import { ReactComponent as Report2Icon } from "../assets/svg/report2.svg";
import { ReactComponent as ZoneIcon } from "../assets/svg/zone.svg";
import { ReactComponent as ClearingIcon } from "../assets/svg/clearing.svg";
import { ReactComponent as ClearingActive } from "../assets/svg/clearingactive.svg";
import { ReactComponent as ZoneActiveIcon } from "../assets/svg/zoneactive.svg";
import { ReactComponent as Cloud2 } from "../assets/svg/cloud-lightning.svg";
import { ReactComponent as Cloud } from "../assets/svg/cloud.svg";
import * as yup from "yup";

export const currentDate = new Date().toISOString().split("T")[0];

export const adminSidebarButtons: SidebarButtonType[] = [
  {
    id: 1,
    title: "Home",
    path: "/dashboard",
    iconActive: HomeIcon,
    icon: Home2Icon,
  },
  {
    id: 2,
    title: "Manage Profile",
    path: "/dashboard/manage-profile",
    iconActive: ClearingActive,
    icon: ClearingIcon,
  },

  {
    id: 3,
    title: "Reports",
    path: "/dashboard/outward",
    p: "",
    iconActive: ReportIcon,
    icon: Report2Icon,
    dropdownList: [
      {
        id: 1,
        title: "Outward Request",
        path: "/dashboard/outward",
        iconActive: ReportIcon,
        icon: Report2Icon,
      },
      {
        id: 2,
        title: "Inward Request",
        path: "/dashboard/inward",
        iconActive: ZoneActiveIcon,
        icon: ZoneIcon,
      },
      {
        id: 3,
        title: "Finacle Spool",
        path: "/dashboard/finacle",
        iconActive: ReportIcon,
        icon: Report2Icon,
      },
    ],
  },

  {
    id: 4,
    title: "Trans",
    path: "/dashboard/trans",
    iconActive: TransactIcon,
    icon: Transact2Icon,
  },
];

export const makerSidebarButtons: SidebarButtonType[] = [
  {
    id: 1,
    title: "Home",
    path: "/dashboard",
    iconActive: HomeIcon,
    icon: Home2Icon,
  },
  {
    id: 2,
    title: "Limit Exemption",
    path: "/dashboard/limit-exemption",
    iconActive: Cloud2,
    icon: Cloud,
    dropdownList: [
      {
        id: 1,
        title: "Outward Request",
        path: "/dashboard/outward",
        iconActive: ReportIcon,
        icon: Report2Icon,
      },
      {
        id: 2,
        title: "Inward Request",
        path: "/dashboard/inward",
        iconActive: ZoneActiveIcon,
        icon: ZoneIcon,
      },
      {
        id: 3,
        title: "Finacle Spool",
        path: "/dashboard/finacle",
        iconActive: ReportIcon,
        icon: Report2Icon,
      },
    ],
  },
  {
    id: 3,
    title: "Reports",
    path: "/dashboard/outward",
    iconActive: ReportIcon,
    icon: Report2Icon,
    dropdownList: [
      {
        id: 1,
        title: "Outward Request",
        path: "/dashboard/outward",
        iconActive: ReportIcon,
        icon: Report2Icon,
      },
      {
        id: 2,
        title: "Inward Request",
        path: "/dashboard/inward",
        iconActive: ZoneActiveIcon,
        icon: ZoneIcon,
      },
      {
        id: 3,
        title: "Finacle Spool",
        path: "/dashboard/finacle",
        iconActive: ReportIcon,
        icon: Report2Icon,
      },
    ],
  },

  {
    id: 4,
    title: "Trans",
    path: "/dashboard/trans",
    iconActive: TransactIcon,
    icon: Transact2Icon,
  },
];
export const checkerSidebarButtons: SidebarButtonType[] = [
  {
    id: 1,
    title: "Home",
    path: "/dashboard",
    iconActive: HomeIcon,
    icon: Home2Icon,
  },
  {
    id: 2,
    title: "Approve Limit",
    path: "/dashboard/approve-limit",
    iconActive: ClearingActive,
    icon: ClearingIcon,
  },

  {
    id: 3,
    title: "Reports",
    path: "/dashboard/outward",
    iconActive: ReportIcon,
    icon: Report2Icon,
    dropdownList: [
      {
        id: 1,
        title: "Outward Request",
        path: "/dashboard/outward",
        iconActive: ReportIcon,
        icon: Report2Icon,
      },
      {
        id: 2,
        title: "Inward Request",
        path: "/dashboard/inward",
        iconActive: ZoneActiveIcon,
        icon: ZoneIcon,
      },
      {
        id: 3,
        title: "Finacle Spool",
        path: "/dashboard/finacle",
        iconActive: ReportIcon,
        icon: Report2Icon,
      },
    ],
  },

  {
    id: 4,
    title: "Trans",
    path: "/dashboard/trans",
    iconActive: TransactIcon,
    icon: Transact2Icon,
  },
];
interface LoadingType {
  loading: string;
}
export const loadingData: LoadingType[] = [
  {
    loading: "loading...",
  },
];
export const uploadneftTable: UploadNeftType[] = [
  {
    sn: "1",
    batch: "17928H",
    debitacc: "82890178829",
    count: "64",
    amount: "100208",
    date: "06-05-2023",
    invalidcount: "8",
    validcount: "9",
    percentage: "Percentage Completed",
    button: "Actions",
  },
];

export const batchData: BatchTableProps[] = [
  {
    refno: "1",
    button: "Select",
    senderacc: "81889189",
    sendername: "Munah Babajomi",
    beneacc: "81889189",
    benname: "Akin Adesola",
    benbank: "GTBank",
    bensortcode: "78998",
    amount: "8000",
    date: "08-09-2021",
    status: "Correct",
  },
];
export const tableDatas: TableProps[] = [
  {
    sn: "1",
    firstname: "Munah",
    lastname: "Babajomi",
    branch: "Ikeja",
    id: "234NEFT64",
    button: "View",
    email: "munal@bmail.com",
    creator: "Israel Moses",
    role: "Creator",
    link: "Check",
    status: "Active",
  },
  {
    sn: "2",
    firstname: "Adetola",
    lastname: "Ayotola",
    id: "234NEFT64",
    button: "View",
    creator: "Moses Simon",
    branch: "Ikeja",
    email: "munal@bmail.com",
    role: "Creator",
    status: "Deleted",
    link: "Check",
  },
];

export const uMtableDatas: UMTableProps[] = [
  {
    sn: "1",
    firstname: "Munah",
    lastname: "Babajomi",
    email: "munal@bmail.com",
    role: "Creator",
    status: "Active",
    createdBy: "Bamidele",
    dateCreated: "23/04/2023",
    action: "string",
  },
  {
    sn: "2",
    firstname: "Munah",
    lastname: "Babajomi",
    email: "munal@bmail.com",
    role: "Creator",
    status: "Active",
    createdBy: "Bamidele",
    dateCreated: "23/04/2023",
    action: "string",
  },
];

export const RoleData: TableProps[] = [];
export const ReportTable: ReportTableProps[] = [
  {
    sn: "1",
    branchcode: "3456AB",
    branchname: "Ikeja Branch 01",
    amount: "200",
  },
];

export const NeftData: NeftTableProps[] = [
  {
    sn: "12345",
    account: 21878000123,
    name: "Munah Babajomi",
    benacc: 21878000123,
    amount: 5000,
    bank: "FIRST BANK",
    sort: "089620",
    date: "12-02-2023",
    beneficiary: "Babajomi Samuel",
    status: "correct",
  },
  {
    sn: "12345",
    account: 21878000123,
    name: "Munah Babajomi",
    benacc: 21878000123,
    amount: 5000,
    bank: "FIRST BANK",
    sort: "089620",
    date: "12-02-2023",
    beneficiary: "Babajomi Samuel",
    status: "correct",
  },
  {
    sn: "12345",
    account: 21878000123,
    name: "Munah Babajomi",
    benacc: 21878000123,
    amount: 5000,
    bank: "FIRST BANK",
    sort: "089620",
    date: "12-02-2023",
    beneficiary: "Babajomi Samuel",
    status: "correct",
  },
  {
    sn: "12345",
    account: 21878000123,
    name: "Munah Babajomi",
    benacc: 21878000123,
    amount: 5000,
    bank: "FIRST BANK",
    sort: "089620",
    date: "12-02-2023",
    beneficiary: "Babajomi Samuel",
    status: "correct",
  },
  {
    sn: "12345",
    account: 21878000123,
    name: "Munah Babajomi",
    benacc: 21878000123,
    amount: 5000,
    bank: "FIRST BANK",
    sort: "089620",
    date: "12-02-2023",
    beneficiary: "Babajomi Samuel",
    status: "correct",
  },
  {
    sn: "12345",
    account: 21878000123,
    name: "Munah Babajomi",
    benacc: 21878000123,
    amount: 5000,
    bank: "FIRST BANK",
    sort: "089620",
    date: "12-02-2023",
    beneficiary: "Babajomi Samuel",
    status: "correct",
  },
];

export interface Testp {
  id: number | string;
  value: string | number;
  label: string;
}
export interface LimitType {
  value: number;
  label: string;
}
export interface ActionProps {
  title: string;
  action?: (id: any) => any;
}

export const DataBranch: Testp[] = [
  {
    id: 1,
    value: "Branch Report",
    label: "Branch Report",
  },
  {
    id: 2,
    value: "Total Bank-Wide Report",
    label: "Total Bank-Wide Report",
  },
  {
    id: 3,
    value: "General Report",
    label: "General Report",
  },
];
export const TestDaa: Testp[] = [
  {
    id: 1,
    value: "Test",
    label: "Test",
  },
  {
    id: 2,
    value: "Adetola",
    label: "Adetola",
  },
];
export const limitList: Testp[] = [
  { id: 1, value: 0, label: "Set Expiry Date" },
  {
    id: 2,
    value: 1,
    label: "One-Time",
  },
];

export const roleList: Testp[] = [
  {
    id: 1,
    value: "Checker",
    label: "Checker",
  },
  {
    id: 2,
    value: "Maker",
    label: "Maker",
  },
  {
    id: 3,
    value: "Admin",
    label: "Admin",
  },
  {
    id: 4,
    value: "SUPERUSER",
    label: "SUPERUSER",
  },
];

export const channelList: Testp[] = [
  {
    id: 1,
    value: "NIP",
    label: "NIP",
  },
  {
    id: 2,
    value: "ETransact",
    label: "ETransact",
  },
];

export const StandingData: Testp[] = [
  {
    id: 1,
    value: "Anya Godswill",
    label: "Sender's Name",
  },
  {
    id: 3,
    value: "Anya Godswill",
    label: "Sender's Account",
  },
  {
    id: 2,
    value: "Anya Godswill",
    label: "Beneficiary Account",
  },
];

export const StandingDatas: Testp[] = [
  {
    id: 1,
    value: "Anya Godswill",
    label: "Sender's Name",
  },
  {
    id: 2,
    value: "Anya Godswill",
    label: "Sender's Account",
  },
  {
    id: 3,
    value: "Anya Godswill",
    label: "Beneficiary Account",
  },
  {
    id: 4,
    value: "Anya Godswill",
    label: "Beneficiary Bank",
  },
];
export const myData: Testp[] = [
  {
    id: 1,
    value: "Anya Godswill",
    label: "Sender's Name",
  },
  {
    id: 2,
    value: "Anya Godswill",
    label: "Sender's Account",
  },
  {
    id: 3,
    value: "Anya Godswill",
    label: "Beneficiary Account",
  },
  {
    id: 4,
    value: "Anya Godswill",
    label: "Beneficiary Bank",
  },
];

export const dataInfoTable: Testp[] = [
  {
    id: 1,
    value: "5",
    label: "Failed transactions",
  },

  {
    id: 2,
    value: "8",
    label: "Completed transactions",
  },
];

export const role: Testp[] = [
  {
    id: 1,
    value: "Anya Godswill",
    label: "Sender's Name",
  },
  {
    id: 2,
    value: "Anya Godswill",
    label: "Sender's Account",
  },
];

export const loginSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
  token: yup.string().required("Token is required"),
});

export const limitSchema = yup.object().shape({
  oneTimeLimit: yup
    .number()
    .typeError("Enter your One Time Limit")
    .required("Enter your One Time Limit"),
  dailyLimit: yup
    .number()
    .typeError("Enter your Daily Limit")
    .required("Enter your Daily Limit"),

  remarks: yup.string().required("Enter your Remark"),
});
