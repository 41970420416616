import { configureStore } from "@reduxjs/toolkit";
import { adminApi } from "../services/adminService";
import { authApi } from "../services/authService";
import { limitApi } from "../services/limitService";
import rootReducer from "./rootReducer";
export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([authApi.middleware, limitApi.middleware, adminApi.middleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
