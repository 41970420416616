import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 24px;
`;
export const FlexWrapper = styled.div`
  display: flex;
`
export const LeftContainer = styled.div`
  font-family: "GothamBold";
  font-size: 30px;
  width: 50%;
  padding-top: 20px;
`;
export const RightContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 50%;
  gap: 30px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
`;
export const ButtonHolder = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
`;
export const InputHolder = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 29px;
  color: #2d2235;
`;
