import React, { useEffect, useState } from "react";
import PrimaryButton from "../../components/Buttons/Button";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { ButtonWrap, Container, EditButtonWrap, EditField, FieldWrap, HeaderText } from "./style";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as ExIcon } from "../../assets/svg/f.svg";
import { currentDate, limitList, limitSchema } from "../../utils/config";
import TextArea from "../../components/TextArea";
import Date from "../../components/Date/Date";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import {
    useUpdateLimitMutation,
  useGetAllApproversQuery,
  useGetAccDetailsMutation,
} from "../../services/limitService";
import { useSelector } from "react-redux";
import { errorMonitor } from "events";
import { FaGalacticSenate } from "react-icons/fa";
const BASE_URL = process.env.REACT_APP_BASEURL;

interface accountDataInterface {
    accountName : string
    accountNo : string
    remarks : string
    dailyLimit : number
    oneTimeLimit : number
    limitType : string
  }

const LimitExemption = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [selectedType, setSelectedType] = useState<number>(0);
  const [selectedApprover, setSelectedApprover] = useState("");
  const [selectedDate, setSelectedDate] = useState<string | Date>("");
  const [limitError, setLimitError] = useState("");
  const [approverError, setApproverError] = useState("");
  const [dateError, setDateError] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [accountNoError, setAccountNoError] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [disable, setDisable] = useState(false);
  const [showSecondSegment, setShowSecondSegment] = useState<boolean>(false);
  const [accountData, setAccountData] = useState<accountDataInterface>( {
    accountName : "",
    accountNo : "",
    remarks : "",
    dailyLimit : 0,
    oneTimeLimit : 0,
    limitType : "",
  });
  const [formData, setFormData] = useState<accountDataInterface>( {
    accountName : accountData.accountName ,
    accountNo : accountData.accountNo,
    remarks : accountData.remarks,
    dailyLimit : accountData.dailyLimit,
    oneTimeLimit : accountData.oneTimeLimit,
    limitType : accountData.limitType,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e)
    setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })
  }
  

  const [getAccDetails] = useGetAccDetailsMutation();

  const initiatedDate = currentDate;

  const [lists, setList] = useState<string[]>([]);

  const getAllApprovers = useGetAllApproversQuery(token);
  const [updateLimit, { isLoading }] = useUpdateLimitMutation();

  const approversList = getAllApprovers?.data?.map((item: any) => ({
    id: item.id,
    value: item.username,
    label: item.username,
  }));

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm(
    {
    resolver: yupResolver(limitSchema),
  });



  const handleClearFields = () => {

    setAccountNo("");
    setAccountName("");
    setSelectedDate("");
    setList([]);
    setSelectedDate("");
    setAccountNumber("")
    setShowSecondSegment(false)

  };

  const handleSubmitForm = async (formValues: any) => {
        const newFormValue = {
          ...formValues,
          
          accountNo: accountData.accountNo,
          limitType: selectedType,
          expiryDate: selectedType === 1 ? "" : selectedDate,
          approvers: lists,
          initiatedDate: initiatedDate,

        };


        try {
          const response = await updateLimit(newFormValue).unwrap();
          toast.success(response?.message);
          setShowSecondSegment(false)
          handleClearFields();
        } catch (error: any) {
          if (error.status === 400) {
            toast.error(error?.data?.Message);
          } else {
            toast.error(error?.data?.Message);
          }
        }
    
    };

  const handleMultiClick = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      approversList?.forEach((list: any) => {
        if (value === list.label) {
          if (!lists.includes(list.value)) {
            setList((prev: any) => [...prev, list.value]);
          }
        }
      });
    } else {
      approversList?.forEach((list: any) => {
        if (value === list.label) {
          setList((prev) => {
            return [...prev.filter((item: any) => item !== list.value)];
          });
        }
      });
    }
  };

 let staffInfo : {accessToken : string} =  JSON.parse(localStorage.getItem("staffInfo") || '{}')
 let accessToken = staffInfo.accessToken

 var options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },

  }

  const fetchUserData = () => {
    setShowSecondSegment(false)

    if (accountNumber == ""){
      toast.error("Account Number is required")
    }
    else
    {

    
    fetch(`${BASE_URL}/limit/get-by-account-number/${accountNumber}`, options)
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.statusCode != 200) {
            toast.error(data.Message);
        }

        if (data.data.deleteFlag == "Y"){
          toast.error("No Transaction Found");
        }
        else{
          setAccountData({
            accountName : data.data.accountName,
            accountNo : data.data.accountNo,
            remarks : data.data.remarks,
            dailyLimit : data.data.dailyLimit,
            oneTimeLimit : data.data.oneTimeLimit,
            limitType : data.data.limitType,
        })
        setShowSecondSegment(true)

        }
       

   
        
      })
    }

  }
  useEffect(() => {
    setValue("accountName", accountData.accountName);
    setValue("accountNo", accountData.accountNo);
    setValue("remarks", accountData.remarks);
    setValue("dailyLimit", accountData.dailyLimit);
    setValue("oneTimeLimit", accountData.oneTimeLimit);
    setValue("limitType", accountData.limitType);
   
  }, [accountData])

  return (
    <div>


      <Header title=" Limit Exemption" />
      <Container onSubmit={(e) => e.preventDefault()}>
        <HeaderText> Limit Exemption</HeaderText>
        <EditField>
          <PrimaryInput
            name={"accountNo"}
            type={"text"}
            placeholder={"Account Number"}
            rightIcon={<></>}
            error={accountNoError}
            label={"Account Number"}
            check={""}
            value={accountNumber}
            padding={""}
            leftIcon={<ExIcon />}
            onChange={(e) => setAccountNumber(e.target.value)}
            password={false}
          />
          <EditButtonWrap>
         <PrimaryButton
           background={"#5c2684"}
           color={"#fff"}
           width={150}
           height={"100%"}
           title={"Submit"}
           icon={""}
           disabled={disable}
           loading={false}
           leftIconName={<></>}
           rightIconName={<></>}
           onClick={fetchUserData}
           leftIcon={false}
           border={""}
         />
       </EditButtonWrap>
        </EditField>
         
      </Container>

      {showSecondSegment  &&
      <Container onSubmit={(e) => e.preventDefault()}>
        <HeaderText> Edit Limit Exemption</HeaderText>
        <FieldWrap>
          <PrimaryInput
            name={"accountNo"}
            disabled={true}
            type={"text"}
            placeholder={"Account Number"}
            rightIcon={<></>}
            error={accountNoError}
            label={"Account Number"}
            check={""}
            // value={formData.accountNo}
            padding={""}
            leftIcon={<ExIcon />}

            register={register}
            password={false}
          />
          <DropDownC
            value={accountData?.limitType == "Set Expiry Date" ? 0 : 1}
            options={limitList}
            errorMessage={limitError}
            label={"Limit Type"}
            setSelectedItem={setSelectedType}
            placeholder={"Set Expiry Date"}
          />
        </FieldWrap>
        <FieldWrap>
          <PrimaryInput
            name={"accountName"}
            type={"text"}
            placeholder={"Account Name"}
            rightIcon={<></>}
            error={errors.accountNo?.message}
            label={"Account Name"}
            check={""}
            // value={formData.accountName}
            disabled={true}
            padding={""}
            leftIcon={<UserIcon />}
            onChange={handleChange}

            register={register}
            password={false}
          />

          <Date
            showModal={false}
            onClosed={function (): void {
              throw new Error("Function not implemented.");
            }}
            isDisabled={selectedType === 1}
            handleDateChange={undefined}
            error={dateError}
            selectDate={setSelectedDate}
            label={"Expiry Date"}
          />
        </FieldWrap>

        <FieldWrap>
          <PrimaryInput
            name={"oneTimeLimit"}
            type={"text"}
            placeholder={"Enter one-time limit"}
            rightIcon={<></>}
            error={errors.oneTimeLimit?.message}
            disabled={undefined}
            label={"One-Time limit "}
            check={""}
            register={register}
            padding={""}
            leftIcon={<ExIcon />}
            // value={formData.oneTimeLimit}
            onChange={handleChange}

            password={false}
          />
          <DropDownC
            value={undefined}
            options={approversList}
            label={"Approver"}
            errorMessage={approverError}
            multi={true}
            setSelectedItem={setSelectedApprover}
            placeholder={"Select Approver"}
            list={lists}
            handleMultiClick={handleMultiClick}
          />
        </FieldWrap>

        <FieldWrap>
          <PrimaryInput
            name={"dailyLimit"}
            type={"text"}
            placeholder={"Enter daily limit"}
            rightIcon={<></>}
            error={errors.dailyLimit?.message}
            disabled={undefined}
            label={"Daily Limit"}
            check={""}
            padding={""}
            register={register}
            leftIcon={<ExIcon />}
            // value={formData.dailyLimit}
            password={false}
          />

          <TextArea
            label={"Remark"}
            name={"remarks"}
            register={register}
            padding={0}
            placeholder={"Type a comment"}
            text={""}
            error={errors.remarks?.message}
            // value={formData.remarks}
          />
        </FieldWrap>

        <ButtonWrap>
          <PrimaryButton
            background={"#ffffff"}
            color={"#5c2684"}
            width={150}
            title={"Clear"}
            isLoading={isLoading}
            icon={""}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={handleClearFields}
            leftIcon={false}
            border={"1px solid #5c2684"}
          />
          <PrimaryButton
            background={"#5c2684"}
            color={"#fff"}
            width={150}
            title={"Submit"}
            isLoading={isLoading}
            icon={""}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={handleSubmit(handleSubmitForm)}
            leftIcon={false}
            border={""}
          />
        </ButtonWrap>
      </Container>
}
    </div>
  );
};

export default LimitExemption;
