import styled from "styled-components";
export const Container = styled.form`
  border-radius: 20px;
  background: #fff;
  padding: 10px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 16px;
`;
export const HeaderText = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 24px;
`;
export const FieldWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const TopTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #2d2235;
`;
export const DetailsWrap = styled.div`
  width: 700px;
  background: #fafbfb;
  border: 0.888112px solid #dfe2e6;
  border-radius: 0px;
  display: flex;
`;

export const LeftD = styled.div`
  width: 45%;
  padding: 15px 24px;
`;

export const RightD = styled.div`
  width: 55%;
  border-left: 0.888112px solid #dfe2e6;
  padding: 15px 24px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 15.986px;
  line-height: 120%;
  color: #2d2235;
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: #2d2235;
`;
export const FieldWrapD = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;
export const CloseWrapper = styled.div`
  cursor: pointer;
`;
