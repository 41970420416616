import { useState, useRef } from "react";
import {
  Wrapper,
  CalendarWrapper,
  TransparentBackdrop,
  Iconwrapper,
  Label,
  Top,
  ErrorMessage,
  Input,
  InputWrapper,
} from "./DateStyle";

import { ReactComponent as CalendarIcon } from "../../assets/svg/calendarblack.svg";
import { Calendar } from "react-calendar";
import "./calender.css";
import format from "date-fns/format";

interface DateType {
  showModal: boolean;
  onClosed: () => void;
  value?: Date | undefined;
  handleDateChange: any;
  selectDate: any;
  label: string;
  isDisabled?: boolean;
  error?: any;
}

const DateInput: React.FC<DateType> = ({
  showModal,
  selectDate,
  label,
  error,
  isDisabled,
}) => {
  const [date, setDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [active, setActive] = useState(false);
  const inputRef = useRef(null);

  const hideCalendar = () => {
    setShowCalendar(false);
  };

  const pickDay = (day: any) => {
    const selectedDate = format(day, "yyyy-MM-dd");
    setDate(selectedDate);
    selectDate(selectedDate);
    hideCalendar();
  };

  const handleBorder = () => {
    setActive(!active);
  };

  return (
    <Wrapper key="DateInput" opacity={isDisabled === true && 0.5}>
      <Top>
        <Label>{label}</Label>
      </Top>
      <InputWrapper
        border={active ? "1px solid #C2684" : "1px solid #ececec"}
        ref={inputRef}
        onFocus={handleBorder}
        onClick={() => setShowCalendar((prev) => !prev)}
        onBlur={() => setActive(false)}
      >
        <Input
          type="text"
          placeholder="DD/MM/YY"
          readOnly
          value={date}
          disabled={isDisabled}
        />
        <Iconwrapper>
          <label htmlFor="date">
            <CalendarIcon />
          </label>
        </Iconwrapper>
      </InputWrapper>
      {showCalendar && isDisabled === false ? (
        <>
          <CalendarWrapper>
            <Calendar onClickDay={pickDay} className={"calendar"} />
          </CalendarWrapper>
          <TransparentBackdrop onClick={hideCalendar} />
        </>
      ) : null}

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};

export default DateInput;
