import styled from "styled-components";
import { Link } from "react-router-dom";

interface LogoProps {
  justify: string;
}
interface SProps {
  width: string;
}
interface ButtonColorProps {
  color: string;
}

export const MainContainer = styled.div`
  padding: 20px;
  background-color: white;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 280px;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  background: white;
  display: flex;
  align-items: center;
`;

export const ListWrapper = styled.div`
  width: max-content;
  z-index: 15;
  background: #5c2684;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

export const SidebarNav = styled.nav`
  height: 85%;
  display: flex;
  flex: 1;
  scroll-behavior: smooth;
  justify-content: center;
  position: sticky;
  top: 0;
  overflow: none;
  transition: 350ms;
  z-index: 10;
`;

export const SidebarWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SidebarParentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
`;

export const LogoutWrapper = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  align-items: center;
  width: 100%;
`;

export const IconButtons = styled.div`
  display: flex;
  gap: 4px;
  margin-top: -0.6rem;
  padding: 0.1rem;
  align-items: center;
  outline: none;
  cursor: pointer;
  border: none;
`;

export const ButtonTexts = styled.div<ButtonColorProps>`
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  margin-left: 7px;
  //padding-top: 7px;
  color: ${(props) => props.color};
`;

export const SidebarLabel = styled.span`
  margin-left: 10px;
  text-decoration: none;
  font-size: 14px;
`;

export const SidebarLabelSub = styled.span`
  margin-left: 12px;
  font-size: 13px;
`;
export const MainTitle = styled.div`
  display: flex;
  align-items: center;
`;
export const DropIcon = styled.div``;

export const DropdownLink = styled(Link)`
  padding-left: 1.5rem !important;
  display: flex;
  padding: 14px 12px;
  align-items: center;
  text-decoration: none;
  color: #807984;
  font-size: 14px;
  width: 87%;

  &:hover {
    background: #d7cbe2;
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
    color: #5c2684;
  }
`;

export const LinkWrap = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  &:hover {
    background: #d7cbe2;
    cursor: pointer;
    border-radius: 10px;
    padding: 12px;
    text-decoration: none;
    color: #5c2684;
  }
`;
export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border: solid red;
  transition: 0.5s ease;
`;
