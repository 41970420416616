import React from "react";
import {
  HiHome,
  HiOutlineUserGroup,
  HiTrendingUp,
  HiOutlineBadgeCheck,
  HiArchive,
  HiClipboardList,
  HiCurrencyDollar,
} from "react-icons/hi";
import { SidebarProps } from "../../types/Types";

export const AdminSidebarData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Manage Profile",
    path: "/manage-profile",
    icon: HiOutlineUserGroup,
  },
  {
    title: "Approve User",
    path: "/approve-user",
    icon: HiArchive,
  },
];

export const SuperUserSidebarData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Manage Profile",
    path: "/manage-profile",
    icon: HiOutlineUserGroup,
  },
];

export const MakerSidebarData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Banks",
    path: "/bank",
    icon: HiCurrencyDollar,
  },
  {
    title: "Limit Exemption",
    path: "/limit-exemption",
    icon: HiOutlineBadgeCheck,

    subNav: [
      {
        title: "Create Limit",
        path: "/limit-exemption",
        icon: HiOutlineBadgeCheck,
      },
      {
        title: "Edit Limit",
        path: "/edit-limit",
        icon: HiOutlineBadgeCheck,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports",
    icon: HiClipboardList,

    subNav: [
      {
        title: "Outward Request",
        path: "/reports/outward",
        icon: HiClipboardList,
      },
      {
        title: "Inward Request",
        path: "/reports/inward",
        icon: HiClipboardList,
      },
      {
        title: "Bank Switch Report",
        path: "reports/bank-switch",
        icon: HiClipboardList,
      },
      {
        title: "Finacle Spool",
        path: "reports/finacle",
        icon: HiClipboardList,
      },
    ],
  },
  {
    title: "Trans",
    path: "/trans",
    icon: HiTrendingUp,
  },
];

export const CheckerSidebarData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },

  {
    title: "Approval",
    path: "/approval",
    icon: HiOutlineBadgeCheck,

    subNav: [
      {
        title: "Limit",
        path: "/approval/limit",
        icon: HiHome,
      },
      {
        title: "Bank Switch",
        path: "/approval/switch",
        icon: HiHome,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports",
    icon: HiClipboardList,

    subNav: [
      {
        title: "Inward Request",
        path: "/reports/inward",
        icon: HiClipboardList,
      },
      {
        title: "Outward Request",
        path: "/reports/outward",
        icon: HiClipboardList,
      },
      {
        title: "Bank Switch Report",
        path: "reports/bank-switch",
        icon: HiClipboardList,
      },
      {
        title: "Finacle Spool",
        path: "reports/finacle",
        icon: HiClipboardList,
      },
    ],
  },
  {
    title: "Trans",
    path: "/trans",
    icon: HiTrendingUp,
  },
];
