import React, { useEffect, useState } from "react";
import Date from "../../components/Date/Date";
import Header from "../../components/Header/Header";
import { FaGithubAlt } from "react-icons/fa";
import toast from "react-hot-toast";
import { BoldText, Main } from "../ApproveLimit/style";
import {
  Container,
  FieldWrap,
  HeaderText,
  CloseWrapper,
  GenerateButtonWrap,
  Status,
  Top,
  TopTitle,
  FieldWrapD,
  DetailsWrap,
  LeftD,
  RightD,
  Text,
  Select,
  SelectDiv,
} from "./style";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import PrimaryButton from "../../components/Buttons/Button";
import PrimaryTable from "../../components/Table";
import GeneralModal from "../../components/Modal/GeneralModal";
import { useGetSwitchReportMutation } from "../../services/limitService";
import PuffLoader from "../../components/Loader";

const BankSwitchReport = () => {
  const [details, setDetails] = useState<any | undefined>();
  const [openView, setOpenView] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [switchReportList, setSwitchReportList] = useState<any[]>([]);
  const [selectedNumber, setSelectedNumber] = useState<number>(5);
  const [getSwitchReport, { isLoading }] = useGetSwitchReportMutation();

  const handleGet = async () => {
    try {
      const response = await getSwitchReport({
        pageSize: selectedNumber,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        pageNumber: 1,
      }).unwrap();
      setSwitchReportList(response);
      setSelectedStartDate("");
      setSelectedEndDate("");
    } catch (error: any) {
      setSelectedStartDate("");
      setSelectedEndDate("");
    }
  };
  useEffect(() => {
    handleGet();
  }, [selectedNumber]);

  const handleGenerate = () => {
    handleGet();
  };
  const handleModalClose = () => {
    setOpenView(false);
  };
  const header = [
    "S/N",
    "Bank Id",
    "Bank Code",
    "Channel",
    "Action",
    "Created On",
    "Created By",
    "Action",
  ];

  const queryList = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "150", value: 150 },
    { label: "200", value: 200 },
    { label: "300", value: 300 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
    { label: "2000", value: 2000 },
    { label: "5000", value: 5000 },
  ];

  const handleView = async (requestId: string) => {
    const filter = switchReportList?.find(
      (item) => item.requestId === requestId
    );
    setDetails(filter);
    setOpenView(true);
  };

  const dataBody = switchReportList?.map((item: any, index: number) => [
    index + 1,
    item?.bankId,
    item?.bankCode,
    item?.channel,

    <Status
      key={item.requestId}
      color={item?.action === "Activate" ? "#01B272" : "#C43C20"}
      backgroundColor={
        item?.action === "Activate"
          ? "rgba(1, 178, 114, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {item?.action}
    </Status>,
    item?.createdOn?.slice(0, 10),
    item?.createdBy?.slice(0, item.createdBy?.indexOf("@")),
    <PrimaryButton
      key={item.rowId}
      background={"white"}
      color={"#5c2684"}
      width={""}
      title={"View"}
      loading={false}
      leftIconName={<></>}
      rightIconName={<></>}
      onClick={() => handleView(item?.requestId)}
      border={"1px solid #5c2684"}
    />,
  ]);

  const handleSelection = (e: any) => {
    const value = e.target.value;
    setSelectedNumber(value);
    handleGet();
  };

  return (
    <>
      <Header title="Report" />
      <Container>
        <HeaderText>Bank Switch Report</HeaderText>

        <FieldWrap>
          <Date
            showModal={false}
            onClosed={function (): void {
              throw new Error("Function not implemented.");
            }}
            value={undefined}
            isDisabled={false}
            handleDateChange={undefined}
            selectDate={setSelectedStartDate}
            label={"Start Date"}
          />
          <Date
            showModal={false}
            onClosed={function (): void {
              throw new Error("Function not implemented.");
            }}
            value={undefined}
            isDisabled={false}
            handleDateChange={undefined}
            selectDate={setSelectedEndDate}
            label={"End Date"}
          />
        </FieldWrap>

        <GenerateButtonWrap>
          <PrimaryButton
            background={"#5c2684"}
            color={"#fff"}
            width={150}
            title={"Generate"}
            icon={""}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={handleGenerate}
            leftIcon={false}
            border={""}
          />
        </GenerateButtonWrap>

        <SelectDiv>
          <Select>
            <label htmlFor="mySelect">Page Size: </label>
            <select id="mySelect" onChange={handleSelection}>
              {queryList?.map((data) => (
                <option value={data?.value}>{data?.label}</option>
              ))}
            </select>
          </Select>
        </SelectDiv>

        {isLoading ? (
          <PuffLoader />
        ) : (
          <>
            {dataBody.length > 1 ? (
              <PrimaryTable header={header} body={dataBody} />
            ) : (
              <Main>
                {" "}
                <FaGithubAlt size={90} color="#5C2684" />
                <BoldText>No record found!</BoldText>
              </Main>
            )}
          </>
        )}
      </Container>

      {openView && (
        <GeneralModal
          general={true}
          handleModalClose={function (): void {
            throw new Error("Function not implemented.");
          }}
        >
          <Top>
            <TopTitle>Switch Details</TopTitle>
            <CloseWrapper onClick={handleModalClose}>
              <CloseIcon />
            </CloseWrapper>
          </Top>
          <FieldWrapD>
            <DetailsWrap>
              <LeftD>
                <Text>Request Id</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.requestId}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Bank Id</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.bankId}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Action</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.action}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Channel</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.channel}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Created On</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.createdOn?.slice(0, 10)}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Created By</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.createdBy}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Approved By</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.approvedBy}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Approved On</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.approvedOn.slice(0, 10)}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Bank Code</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.bankCode}</Text>{" "}
              </RightD>
            </DetailsWrap>
          </FieldWrapD>
        </GeneralModal>
      )}
    </>
  );
};

export default BankSwitchReport;
