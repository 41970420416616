import Header from "../../components/Header/Header";
import Overview from "../../components/Overview";
import QuickNavCard from "../../components/QuickNav/QuickCard";

const Home = () => {
  const staffInfoJSON = localStorage.getItem("staffInfo");

  const localInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};

  return (
    <>
      <>
        <Header title={`${localInfo?.user?.role}`} />
        <Overview />
        <QuickNavCard />
      </>
    </>
  );
};

export default Home;
