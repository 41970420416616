import React from "react";
import {
  Container,
  Deparment,
  ImageContainer,
  LeftContainer,
  LeftHeader,
  RightContainer,
  UserContainer,
  Username,
  UsernameWrapper,
} from "./style";

const Header = ({ title = "Dashboard" }) => {
  const staffInfoJSON = localStorage.getItem("staffInfo");

  const localInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};

  return (
    <Container>
      <LeftContainer>
        <LeftHeader>{title}</LeftHeader>
      </LeftContainer>
      <RightContainer>
        <UserContainer>
          <ImageContainer>
            {localInfo?.user?.displayName.slice(0, 1)}
          </ImageContainer>
          <UsernameWrapper>
            <Username>{localInfo?.user?.displayName}</Username>
            <Deparment>
              {localInfo?.user?.department === "NA"
                ? ""
                : localInfo?.user?.department}
            </Deparment>
          </UsernameWrapper>
        </UserContainer>
      </RightContainer>
    </Container>
  );
};

export default Header;
