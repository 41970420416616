import { combineReducers } from "@reduxjs/toolkit";
import { adminApi } from "../services/adminService";
import { authApi } from "../services/authService";
import { limitApi } from "../services/limitService";
import { StaffDataReducer } from "./slice";
export default combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [limitApi.reducerPath]: limitApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  StaffDataReducer: StaffDataReducer,
});
