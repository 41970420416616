import React, { useEffect, useState } from "react";
import PrimaryButton from "../../components/Buttons/Button";
import Header from "../../components/Header/Header";
import PrimaryTable from "../../components/Table";
import {
  useApproveLimitMutation,
  useGetSwitchApprovalQuery,
  useApproveSwitchMutation,
  SwitchResponseData,
} from "../../services/limitService";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
  Container,
  Top,
  TopTitle,
  FieldWrapD,
  DetailsWrap,
  LeftD,
  RightD,
  Text,
  CloseWrapper,
  ButtonWrap,
  Status,
  TableContainer,
} from "./style";
import GeneralModal from "../../components/Modal/GeneralModal";
import PuffLoader from "../../components/Loader";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const Switch = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [details, setDetails] = useState<SwitchResponseData | undefined>();
  const [openView, setOpenView] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [limitContainer, setLimitContainer] = useState<
    SwitchResponseData[] | undefined
  >([]);

  const switchList = useGetSwitchApprovalQuery(token);
  const [approveSwitch] = useApproveSwitchMutation();

  const header = [
    "S/N",
    "Bank Id",
    "Bank Code",
    "Channel",
    "Action",
    "Created By",
    "Action",
  ];

  useEffect(() => {
    setLimitContainer(switchList?.data);
  }, [switchList]);

  const dataBody = limitContainer?.map((item: any, index: number) => [
    index + 1,
    item?.bankId,
    item?.bankCode,
    item?.channel,

    <Status
      key={item.requestId}
      color={item?.action === "Activate" ? "#01B272" : "#C43C20"}
      backgroundColor={
        item?.action === "Activate"
          ? "rgba(1, 178, 114, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {item?.action}
    </Status>,
    item?.createdBy?.slice(0, item.createdBy?.indexOf("@")),
    <PrimaryButton
      key={item.rowId}
      background={"white"}
      color={"#5c2684"}
      width={""}
      title={"View"}
      loading={false}
      leftIconName={<></>}
      rightIconName={<></>}
      onClick={() => handleView(item?.requestId)}
      border={"1px solid #5c2684"}
    />,
  ]);

  const handleView = async (requestId: string) => {
    const filter = limitContainer?.find((item) => item.requestId === requestId);
    setDetails(filter);
    setOpenView(true);
  };

  const handleModalClose = () => {
    setOpenView(false);
  };

  const handleApprove = async () => {
    try {
      const response = await approveSwitch({
        requestId: details?.requestId as string,
        isApproved: true,
      }).unwrap();

      toast.success(response?.message);

      switchList.refetch();

      setOpenView(false);
    } catch (error: any) {
      toast.error(error?.data?.Message);
      setOpenView(false);
    }
  };

  const handleDecline = async () => {
    try {
      const response = await approveSwitch({
        requestId: details?.requestId as string,
        isApproved: false,
      }).unwrap();

      toast.success(response?.message);

      switchList.refetch();

      setOpenView(false);
    } catch (error: any) {
      toast.error(error?.data?.Message);
      setOpenView(false);
    }
  };

  const onChange = (e: any) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <>
      <Header title="Pending Bank Switch" />
      <Container>
        <TableContainer>
          {switchList.isLoading ? (
            <PuffLoader />
          ) : (
            <>
              <PrimaryTable header={header} body={dataBody} />
            </>
          )}
        </TableContainer>

        {openView && (
          <GeneralModal
            general={true}
            handleModalClose={function (): void {
              throw new Error("Function not implemented.");
            }}
          >
            <Top>
              <TopTitle>Switch Details</TopTitle>
              <CloseWrapper onClick={handleModalClose}>
                <CloseIcon />
              </CloseWrapper>
            </Top>
            <FieldWrapD>
              <DetailsWrap>
                <LeftD>
                  <Text>Request Id</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.requestId}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Bank Id</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.bankId}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Action</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.action}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Channel</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.channel}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Created On</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.createdOn?.slice(0, 10)}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Created By</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.createdBy}</Text>{" "}
                </RightD>
              </DetailsWrap>
            </FieldWrapD>
            <ButtonWrap>
              <PrimaryButton
                background={"#E21A1A"}
                color={"#ffffff"}
                width={140}
                title={"Decliine"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleDecline}
                leftIcon={false}
                border={"1px solid #E21A1A"}
              />
              <PrimaryButton
                background={"#01B272"}
                color={"#ffffff"}
                width={140}
                title={"Approve"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleApprove}
                leftIcon={false}
                border={"1px solid #01B272"}
              />
            </ButtonWrap>
          </GeneralModal>
        )}
      </Container>
      {/* </ContentLayout> */}
    </>
  );
};

export default Switch;
