import styled from "styled-components";
interface StatusProp {
  color?: string;
  backgroundColor?: string;
}
export const Container = styled.div`
  border-radius: 20px;
  background: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const HeaderText = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 24px;
`;
export const TopContainer = styled.div`
  display: flex;
  flex-flow: column;
`;
export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const TopTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #2d2235;
`;
export const DetailsWrap = styled.div`
  width: 700px;
  background: #fafbfb;
  border: 0.888112px solid #dfe2e6;
  border-radius: 0px;
  display: flex;
`;

export const LeftD = styled.div`
  width: 45%;
  padding: 15px 24px;
`;

export const RightD = styled.div`
  width: 55%;
  border-left: 0.888112px solid #dfe2e6;
  padding: 15px 24px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #2d2235;
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: #2d2235;
`;
export const FieldWrapD = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;
export const CloseWrapper = styled.div`
  cursor: pointer;
`;
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const Status = styled.div<StatusProp>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 12px;
  padding: 4px 8px;
`;
export const TableContainer = styled.div`
  display: flex;
  flex-flow: column;
  /* padding-inline: 24px; */
`;

export const DataDiv = styled.div``;

export const Main = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: auto 250px;
  height: 50vh;
`;
export const BoldText = styled.h3`
  color: #151717;
  font-size: clamp(20px, 1.5vw, 28px);
  font-weight: 700;
`;
