import React, { useEffect, useState } from "react";
import PrimaryButton from "../../components/Buttons/Button";
import Header from "../../components/Header/Header";
import PrimaryTable from "../../components/Table";
import { FaGithubAlt } from "react-icons/fa";
import {
  LimitResponse,
  useApproveLimitMutation,
  useGetAllLimitsQuery,
  useGetLimitByAccNoMutation,
  useGetLimitMutation,
} from "../../services/limitService";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
  Container,
  Top,
  TopTitle,
  FieldWrapD,
  DetailsWrap,
  LeftD,
  RightD,
  Text,
  CloseWrapper,
  ButtonWrap,
  Status,
  TableContainer,
  TopContainer,
  DataDiv,
  Main,
  BoldText,
} from "./style";
import GeneralModal from "../../components/Modal/GeneralModal";
import PuffLoader from "../../components/Loader";
import SearchCard from "../../components/SearchCard";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Approve = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [details, setDetails] = useState<LimitResponse | undefined>();
  const [openView, setOpenView] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [limitContainer, setLimitContainer] = useState<
    LimitResponse[] | undefined
  >([]);

  const limit = useGetAllLimitsQuery(token);
  const [getLimit] = useGetLimitMutation();
  const [approveLimit] = useApproveLimitMutation();
  const [getLimitByAccount, { isLoading }] = useGetLimitByAccNoMutation();
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [limitData, setLimitData] = useState<any>("");
  const header = [
    "Initiated By",
    "Created On",
    // "Daily Limit",
    "Account Number",
    "Expiry Date",
    "Approved",
    "Limit Type",
    "Approver",
    "Action",
  ];

  const navigate = useNavigate();
  const refreshPage = () => {
    navigate(0);
  };
  useEffect(() => {
    setLimitContainer(limit?.data);
  }, [limit]);
  const dataBody = limitContainer?.map((item: any) => [
    <DataDiv key={item.rowId}>
      {item?.initiatedBy?.slice(0, item.initiatedBy?.indexOf("@"))}
    </DataDiv>,
    item?.initiatedDate?.slice(0, 10),
    item?.accountNo,
    // item?.dailyLimit,
    item?.expiryDate?.slice(0, 10),
    <Status
      key={item.rowId}
      color={item?.isActive === true ? "#01B272" : "#C43C20"}
      backgroundColor={
        item?.isActive === true
          ? "rgba(1, 178, 114, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {item?.isApprover === true ? "Approved" : "Not Approved"}
    </Status>,

    item?.limitType,
    item?.limitApprovers?.slice(0, item.limitApprovers?.indexOf("@")),
    <PrimaryButton
      key={item.rowId}
      background={"white"}
      color={"#5c2684"}
      width={""}
      title={"View"}
      loading={false}
      leftIconName={<></>}
      rightIconName={<></>}
      onClick={() => handleView(item?.rowId)}
      border={"1px solid #5c2684"}
    />,
  ]);

  const handleView = async (id: number) => {
    const response = await getLimit({ id: id }).unwrap();
    setDetails(response);
    setOpenView(true);
  };

  const handleModalClose = () => {
    setOpenView(false);
  };

  const handleApprove = async () => {
    try {
      const response = await approveLimit({
        accountNo: details?.accountNo,
        value: true,
      }).unwrap();

      toast.success(response?.message);

      limit.refetch();
      refreshPage();
      setOpenView(false);
    } catch (error: any) {
      toast.error(error?.data?.Message);
      setOpenView(false);
    }
  };

  const handleDecline = async () => {
    try {
      const response = await approveLimit({
        accountNo: details?.accountNo,
        value: false,
      }).unwrap();

      toast.success(response?.message);

      limit.refetch();
      refreshPage();

      setOpenView(false);
    } catch (error: any) {
      toast.error(error?.data?.Message);
      setOpenView(false);
    }
  };

  const onChange = (e: any) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleClick: any = async () => {
    if (value.length === 10) {
      try {
        const response = await getLimitByAccount({
          token: token,
          accountNo: value,
        }).unwrap();

        const arr = [];
        arr.push(response[0]);
        setLimitContainer(arr);
      } catch (error: any) {
        toast.error(error?.data?.Message);
        limit.refetch();
      }
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(value);
    }, 2000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedValue.length < 10) {
        try {
          // limit.refetch();
          setLimitContainer(limit?.data);
        } catch (error) {}
      }
    };

    fetchData();

    return () => {
      fetchData();
    };
  }, [debouncedValue, limit]);

  return (
    <>
      <Header title="Pending Limit" />
      <Container>
        {/* <HeaderText> Limit Exemption</HeaderText> */}

        <TopContainer>
          <SearchCard
            title="Limit Exemption"
            searchtitle="Account Number"
            placeholder="Enter Account Number"
            header={[]}
            dataBody={[]}
            value={value}
            isLoading={isLoading}
            onClick={handleClick}
            onChange={onChange}
          />
        </TopContainer>

        <TableContainer>
          {limit.isLoading ? (
            <PuffLoader />
          ) : (
            <>
              {limitContainer?.length === 0 ? (
                <>
                  {" "}
                  <Main>
                    <FaGithubAlt size={75} color="#5C2684" />
                    <BoldText>No record Found!</BoldText>
                  </Main>
                </>
              ) : (
                <PrimaryTable header={header} body={dataBody} />
              )}
            </>
          )}
        </TableContainer>

        {openView && (
          <GeneralModal
            general={true}
            handleModalClose={function (): void {
              throw new Error("Function not implemented.");
            }}
          >
            <Top>
              <TopTitle>Limit Exemption</TopTitle>
              <CloseWrapper onClick={handleModalClose}>
                <CloseIcon />
              </CloseWrapper>
            </Top>
            <FieldWrapD>
              <DetailsWrap>
                <LeftD>
                  <Text>Account Number</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.accountNo}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>One Time Limit</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.oneTimeLimit}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Daily Limit</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.dailyLimit}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Limit Type</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.limitType}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Expiry Date</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.expiryDate.slice(0, 10)}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Approver</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.limitApprovers}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Remark</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.remarks}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Created On</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.initiatedDate.slice(0, 10)}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Created By</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{details?.initiatedBy}</Text>{" "}
                </RightD>
              </DetailsWrap>
            </FieldWrapD>
            <ButtonWrap>
              <PrimaryButton
                background={"#E21A1A"}
                color={"#ffffff"}
                width={140}
                title={"Decliine"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleDecline}
                leftIcon={false}
                border={"1px solid #E21A1A"}
              />
              <PrimaryButton
                background={"#01B272"}
                color={"#ffffff"}
                width={140}
                title={"Approve"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleApprove}
                leftIcon={false}
                border={"1px solid #01B272"}
              />
            </ButtonWrap>
          </GeneralModal>
        )}
      </Container>
      {/* </ContentLayout> */}
    </>
  );
};

export default Approve;
