import CommingSoon from "../../components/ComingSoon";
import Header from "../../components/Header/Header";
import { Container } from "./style";

const Trans = () => {
  return (
    <>
      <Header title="Finacle" />
      <Container>
        <CommingSoon />
      </Container>
    </>
  );
};

export default Trans;
