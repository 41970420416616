import {
  ButtonWrapper,
  LeftIconContainer,
  IconContainer,
  DropDown,
  ListItem,
  ListItems,
  InvisibleBackDrop,
} from "./ButtonStyles";

import { FaCaretSquareDown, FaCaretSquareUp } from "react-icons/fa";
import { useState } from "react";
import { ActionProps } from "../../utils/config";
import { Oval } from "react-loading-icons";

interface ButtonProps {
  disabled? : boolean;
  background: string;
  color: string;
  width: string | number;
  height?: string | number;
  title: string;
  icon?: string;
  leftIcon?: boolean;
  rightIcon?: boolean;
  loading: boolean;
  leftIconName: React.ReactElement;
  rightIconName: React.ReactElement;
  onClick: (e: any) => void;
  border: string;
  action?: boolean;
  options?: ActionProps[];
  isLoading?: any;
  openDrop?: boolean;
  setOpenDrop?: any;
  item?: any;
  act?: any;
}
const PrimaryButton: React.FC<ButtonProps> = ({
  width,
  color,
  background,
  loading,
  leftIconName,
  rightIconName,
  leftIcon,
  rightIcon,
  icon,
  onClick,
  title,
  border,
  act,
  isLoading,
  item,
  action,
  options,
  height,
  disabled
}) => {
  const [openDrop, setOpenDrop] = useState<boolean>(false);

  const handleGeneralClick = (e: any) => {
    e.preventDefault();
    onClick(item?.username);
    setOpenDrop(!openDrop);
  };

  return (
    <>
      <ButtonWrapper
        width={width}
        height={height}
        color={color}
        background={background}
        type="button"
        onClick={handleGeneralClick}
        border={border}
        disabled={disabled}
      >
        {isLoading ? (
          <Oval stroke="#ffffff" fill="white" width={24} height={24} />
        ) : (
          <>
            {leftIcon && <LeftIconContainer>{leftIconName}</LeftIconContainer>}
            {title}
            {rightIcon && (
              <IconContainer>
                {action ? (
                  <>{openDrop ? <FaCaretSquareUp /> : <FaCaretSquareDown />}</>
                ) : (
                  rightIconName
                )}
              </IconContainer>
            )}
          </>
        )}

        {openDrop && (
          <>
            {act && <InvisibleBackDrop onClick={() => setOpenDrop(false)} />}
            <DropDown>
              <ListItems>
                {options?.map((option: any) => (
                  <ListItem key={option.id} onClick={option.action}>
                    {option.title}
                  </ListItem>
                ))}
              </ListItems>
            </DropDown>
          </>
        )}
      </ButtonWrapper>
    </>
  );
};

export default PrimaryButton;
