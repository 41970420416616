import { useState } from "react";
import ContentLayout from "../../layout/Content";
import Header from "../../components/Header/Header";
import { useSelector } from "react-redux";
import {
  Container,
  ButtonWrap,
  HeaderText,
  DateWrap,
  BottomContainer,
  TableContainer,
  Status,
  DataDiv,
} from "./style";

import PrimaryTable from "../../components/Table";
import PrimaryButton from "../../components/Buttons/Button";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { ReactComponent as SuccessIcon } from "../../assets/svg/securrity.svg";
import {
  useAssignRoleMutation,
  useGetAllUsersQuery,
  useActivateUserMutation,
  useDeactivateUserMutation,
} from "../../services/adminService";
import toast from "react-hot-toast";
import PuffLoader from "../../components/Loader";
import GeneralModal from "../../components/Modal/GeneralModal";
import { roleList } from "../../utils/config";
import PrimaryInput from "../../components/PrimaryInput";

const ProfileManagement = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [open, setOpen] = useState<boolean>(false);
  const [userName, setUserName] = useState("");
  const [roleSelected, setRoleSelected] = useState<string>();
  const [selectedUsername, setSelectedUsername] = useState<string>("");
  const [assignRole, mutationState] = useAssignRoleMutation();
  const { data, refetch, isLoading } = useGetAllUsersQuery(token);
  const [activateUser] = useActivateUserMutation();
  const [deactivateUser] = useDeactivateUserMutation();

  const handleActivate = async () => {
    try {
      const requiredData = {
        username: selectedUsername,
      };
      const response = await activateUser(requiredData).unwrap();
      console.log("s", response);
      if (response.data) {
        toast.success(response?.message);
        refetch();
      }
    } catch (error: any) {
      toast.error(error?.data?.Message);
    }
  };
  const handleDeactivate = async () => {
    try {
      const requiredData = {
        username: selectedUsername,
      };

      const response = await deactivateUser(requiredData).unwrap();
      if (response.data) {
        toast.success(response?.message);
        refetch();
      }
    } catch (error: any) {
      toast.error(error?.data?.Message);
    }
  };

  const userActionList = [
    {
      title: "Activate",
      action: handleActivate,
    },
    {
      title: "Deactivate",
      action: handleDeactivate,
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleUsername = (username: string) => {
    setSelectedUsername(username);
  };

  const header = [
    "UserName",
    "Role Name",
    "Status",
    "Created By",
    "Last Seen",
    "Action",
  ];

  const dataBody = data?.map((item: any) => [
    <DataDiv>{item?.username?.slice(0, item.username?.indexOf("@"))}</DataDiv>,

    item?.role,
    <Status
      key={item.username}
      color={item?.isActive === true ? "#01B272" : "#C43C20"}
      backgroundColor={
        item?.isActive === true
          ? "rgba(1, 178, 114, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {item?.isActive === true ? "Active" : "Inactive"}
    </Status>,
    item?.createdBy?.slice(0, item.createdBy?.indexOf("@")),
    item?.lastLogin?.slice(0, 10),
    <>
      <PrimaryButton
        key={item.username}
        background={"#fff"}
        color={"#5c2684"}
        width={"100%"}
        title={"Action"}
        icon={""}
        rightIcon={true}
        act={true}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={handleUsername}
        leftIcon={false}
        border={"1px solid #5c2684"}
        action={true}
        options={userActionList}
        item={item}
      />
    </>,
  ]);

  const onChange = (e: any) => {
    e.preventDefault();
    setUserName(e.target.value);
  };

  const handleAssign = async () => {
    try {
      const requiredData = {
        username: userName,
        role: roleSelected,
      };

      const response = await assignRole(requiredData).unwrap();
      if (response?.data) {
        setOpen(true);
        toast.success("Role assigned successfully");
        refetch();
      }
    } catch (error: any) {
      toast.error(error?.data?.Message);
    }
  };

  return (
    <>
      <Header title="Profile Management" />
      <ContentLayout>
        <Container>
          <BottomContainer>
            <HeaderText>Assign Role</HeaderText>
            <DateWrap>
              <PrimaryInput
                name={"username"}
                type={"text"}
                placeholder={"username"}
                rightIcon={<></>}
                error={undefined}
                label={"AD username"}
                check={""}
                padding={""}
                leftIcon={<UserIcon />}
                onChange={onChange}
                password={false}
              />
              <DropDownC
                value={undefined}
                placeholder={"Select Role"}
                options={roleList}
                errorMessage={""}
                label={"Role"}
                setSelectedItem={setRoleSelected}
              />
            </DateWrap>

            <ButtonWrap>
              <PrimaryButton
                background={"#5c2684"}
                color={"#fff"}
                width={150}
                title={"Assign Role"}
                icon={""}
                isLoading={mutationState.isLoading}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleAssign}
                leftIcon={false}
                border={""}
              />
            </ButtonWrap>
          </BottomContainer>
          {/* )} */}
          <TableContainer>
            {isLoading ? (
              <PuffLoader />
            ) : (
              <PrimaryTable header={header} body={dataBody} />
            )}
          </TableContainer>
        </Container>

        {open && (
          <GeneralModal
            handleModalClose={handleClose}
            title={"User Created"}
            icon={<SuccessIcon />}
          />
        )}
      </ContentLayout>
    </>
  );
};

export default ProfileManagement;
