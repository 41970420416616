import PrimaryButton from "../../components/Buttons/Button";
import PrimaryInput from "../../components/PrimaryInput";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";

import React from "react";
import {
  ButtonHolder,
  ButtonWrapper,
  Container,
  InputContainer,
  InputHolder,
  LeftContainer,
  FlexWrapper,
  RightContainer,
  Title,
} from "./style";

interface SearchProps {
  title: string;
  searchtitle: string;
  placeholder: string;
  header: string[];
  dataBody: [];
  onClick: () => {};
  value: string;
  onChange: (value: any) => void;
  isLoading?: any;
}

const SearchCard: React.FC<SearchProps> = ({
  title,
  searchtitle,
  placeholder,
  onClick,
  value,
  onChange,
  dataBody,
  isLoading,
}) => {
  return (
    <Container>
      <FlexWrapper>
        <LeftContainer>
          <Title>{title}</Title>
        </LeftContainer>
        <RightContainer>
          <InputContainer>
            <InputHolder>
              <PrimaryInput
                name={"username"}
                type={"text"}
                placeholder={placeholder}
                rightIcon={<></>}
                password={false}
                error={""}
                label={searchtitle}
                check={""}
                padding={""}
                leftIcon={<SearchIcon />}
                value={value}
                onChange={onChange}
                disabled={undefined}
              />
            </InputHolder>
          </InputContainer>
          <ButtonWrapper>
            <ButtonHolder>
              {" "}
              <PrimaryButton
                background={""}
                color={""}
                width={"100%"}
                title={"Search"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                isLoading={isLoading}
                leftIcon={false}
                border={""}
                onClick={onClick}
              />
            </ButtonHolder>
          </ButtonWrapper>
        </RightContainer>
      </FlexWrapper>
    </Container>
  );
};

export default SearchCard;
