import React from "react";
import { BoldText, ComingBtn, Main, ParagraphText } from "./styled";
import { useNavigate } from "react-router-dom";
import { FaGithubAlt } from "react-icons/fa";

const CommingSoon = () => {
  const navigate = useNavigate();
  return (
    <Main>
      <FaGithubAlt size={70} color="#5C2684" />
      <BoldText>Coming Soon...</BoldText>
      <ParagraphText>
        Oh!, our apologies. The page you’re looking for is unavailable at the
        moment.
      </ParagraphText>
      <ComingBtn onClick={() => navigate("/")}>Back to Dashboard</ComingBtn>
    </Main>
  );
};

export default CommingSoon;
