import React, { useEffect, useState } from "react";
import PrimaryButton from "../../components/Buttons/Button";
import Header from "../../components/Header/Header";
import PrimaryTable from "../../components/Table";
import { FaGithubAlt } from "react-icons/fa";
import { ReactComponent as ExIcon } from "../../assets/svg/f.svg";
import {
  BankResponse,
  useGetSwitchBanksMutation,
  useModifyChannelMutation,
} from "../../services/limitService";
import {
  Container,
  FieldWrap,
  TableContainer,
  DataDiv,
  Main,
  BoldText,
  HeaderText,
} from "./style";
import PuffLoader from "../../components/Loader";

import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { channelList } from "../../utils/config";
import GeneralModal from "../../components/Modal/GeneralModal";
import { ButtonWrap, Top, TopTitle } from "../SwitchApproval/style";

const BankSwitch = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );

  const [selectedChannel, setSelectedChannel] = useState<string>("NIP");
  const [bankName, setBankName] = useState<string>("");
  const [action, setAction] = useState<string>("");
  const [openView, setOpenView] = useState<boolean>(false);
  const [bank, setBank] = useState<any>({});
  const [limitContainer, setLimitContainer] = useState<
    BankResponse[] | undefined
  >([]);

  const [bankSwtich, bankSwtichMutation] = useGetSwitchBanksMutation();
  const [modifyBank] = useModifyChannelMutation();

  const header = ["Id", "Bank Code", "Bank Name", "Action"];

  const handleGetBankList = async () => {
    try {
      const response = await bankSwtich({
        channel: selectedChannel,
        token: token,
      }).unwrap();

      if (response?.statusCode === 200) {
        setLimitContainer(response?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetBankList();
  }, [selectedChannel]);

  const filteredList =
    bankName !== ""
      ? limitContainer?.filter((item: any) =>
          item?.bankName.toLowerCase().includes(bankName)
        )
      : limitContainer;

  const handleGetBank = (rowId: number) => {
    const filtered = limitContainer?.find(
      (row: BankResponse) => row.rowId === rowId
    );
    setBank(filtered);
  };
  const handleBankAction = async () => {
    try {
      const response = await modifyBank({
        bankId: bank?.rowId.toString(),
        bankCode: bank?.bankCode,
        action: action,
        channel: selectedChannel,
      }).unwrap();

      toast.success(response?.message);
      setOpenView(false);
    } catch (error: any) {
      toast.error(error?.data?.Message);
    } finally {
    }
  };

  const handleActivate = async () => {
    setOpenView(true);
    setAction("Activate");
  };

  const handleDeactivate = async () => {
    setOpenView(true);
    setAction("Deactivate");
  };
  const handleModalClose = () => {
    setOpenView(false);
  };

  const bankActions = [
    {
      title: "Activate",
      action: handleActivate,
    },
    {
      title: "Deactivate",
      action: handleDeactivate,
    },
  ];
  const dataBody = filteredList?.map((item: any) => [
    <DataDiv key={item.rowId}>{item.rowId}</DataDiv>,
    item?.bankCode,
    item?.bankName,
    <PrimaryButton
      key={item.rowId}
      background={"white"}
      color={"#5c2684"}
      width={""}
      title={"Action"}
      rightIcon={true}
      loading={false}
      leftIconName={<></>}
      leftIcon={false}
      act={true}
      action={true}
      options={bankActions}
      rightIconName={<></>}
      onClick={() => handleGetBank(item?.rowId)}
      border={"1px solid #5c2684"}
    />,
  ]);

  return (
    <>
      <Header title="Bank Switch" />
      <Container>
        <HeaderText> Bank List</HeaderText>

        <FieldWrap>
          <DropDownC
            value={selectedChannel}
            placeholder={"Select Channel"}
            options={channelList}
            errorMessage={""}
            label={"Channel"}
            setSelectedItem={setSelectedChannel}
          />
          <PrimaryInput
            name={"bankName"}
            type={"text"}
            placeholder={"Bank Name"}
            rightIcon={<></>}
            error={undefined}
            label={"Bank Name"}
            check={""}
            value={bankName}
            padding={""}
            leftIcon={<ExIcon />}
            onChange={(e) => setBankName(e.target.value)}
            password={false}
          />
        </FieldWrap>

        <TableContainer>
          {bankSwtichMutation.isLoading ? (
            <PuffLoader />
          ) : (
            <>
              {limitContainer?.length === 0 ? (
                <>
                  {" "}
                  <Main>
                    <FaGithubAlt size={75} color="#5C2684" />
                    <BoldText>No record Found!</BoldText>
                  </Main>
                </>
              ) : (
                <PrimaryTable header={header} body={dataBody} />
              )}
            </>
          )}
        </TableContainer>

        {openView && (
          <GeneralModal
            general={true}
            handleModalClose={function (): void {
              throw new Error("Function not implemented.");
            }}
          >
            <Top>
              <TopTitle>
                Are you sure you want to{" "}
                {action.charAt(0).toLowerCase() + action.slice(1)} this bank?
              </TopTitle>
            </Top>

            <ButtonWrap>
              <PrimaryButton
                background={"#fffff"}
                color={"#5c2684"}
                width={140}
                title={"Cancel"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleModalClose}
                leftIcon={false}
                border={"1px solid #5c2684"}
              />
              <PrimaryButton
                background={"#5c2684"}
                color={"#ffffff"}
                width={140}
                title={"Proceed"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleBankAction}
                leftIcon={false}
                border={"1px solid #5c2684"}
              />
            </ButtonWrap>
          </GeneralModal>
        )}
      </Container>
    </>
  );
};

export default BankSwitch;
