import React from "react";
import {
  Background,
  ModalTexts,
  ModalContent,
  ModalWrapper,
  Top,
  CloseWrapper,
} from "./style";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";

interface ModalProps {
  title?: string;
  icon?: React.ReactElement;
  handleModalClose?: () => void;
  general?: boolean;
  children?: React.ReactNode;
}
const GeneralModal: React.FC<ModalProps> = ({
  title,
  icon,
  handleModalClose,
  general,
  children,
}) => {
  return (
    <>
      {general ? (
        <>
          <Background>
            <ModalWrapper>
              <ModalContent>{children}</ModalContent>
            </ModalWrapper>
          </Background>
        </>
      ) : (
        <>
          <Background>
            <ModalWrapper>
              <ModalContent>
                <Top>
                  <CloseWrapper onClick={handleModalClose}>
                    <CloseIcon />
                  </CloseWrapper>
                </Top>

                <>{icon}</>
                <ModalTexts>{title}</ModalTexts>
              </ModalContent>
            </ModalWrapper>
          </Background>
        </>
      )}
    </>
  );
};
export default GeneralModal;
