import React, { useState } from "react";
import PrimaryButton from "../../components/Buttons/Button";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { ButtonWrap, Container, FieldWrap, HeaderText } from "./style";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as ExIcon } from "../../assets/svg/f.svg";
import { currentDate, limitList, limitSchema } from "../../utils/config";
import TextArea from "../../components/TextArea";
import Date from "../../components/Date/Date";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import {
  useAddLimitMutation,
  useGetAllApproversQuery,
  useGetAccDetailsMutation,
} from "../../services/limitService";
import { useSelector } from "react-redux";

const LimitExemption = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [selectedType, setSelectedType] = useState<number>(0);
  const [selectedApprover, setSelectedApprover] = useState("");
  const [selectedDate, setSelectedDate] = useState<string | Date>("");
  const [limitError, setLimitError] = useState("");
  const [approverError, setApproverError] = useState("");
  const [dateError, setDateError] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [accountNoError, setAccountNoError] = useState("");

  const [getAccDetails] = useGetAccDetailsMutation();

  const initiatedDate = currentDate;

  const [lists, setList] = useState<string[]>([]);

  const getAllApprovers = useGetAllApproversQuery(token);
  const [addlimit, { isLoading }] = useAddLimitMutation();

  const approversList = getAllApprovers?.data?.map((item: any) => ({
    id: item.id,
    value: item.username,
    label: item.username,
  }));

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(limitSchema),
  });

  const handleFetchAccount = async (e: any) => {
    try {
      const { value } = e.target;
      if (value.length === 10) {
        setAccountNoError("");
        try {
          setAccountNo(value);
          const response = await getAccDetails({
            accountNo: value,
            token: token,
          }).unwrap();
          if (response) {
            setAccountName(response?.data);
            toast.success(response?.message);
          }
        } catch (error: any) {
          setAccountName("");
          toast.error(error?.data?.Message);
        }
      } 
      else if (value.length !== 10) {
        setAccountNo(value)

        setAccountName("");
        // setAccountNoError("Account number should be 10");
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const handleClearFields = () => {
    setAccountNo("");
    setAccountName("");
    setSelectedDate("");
    setList([]);
    setSelectedDate("");
    setValue("oneTimeLimit", "", {
      shouldValidate: false,
    });
    setValue("dailyLimit", "", {
      shouldValidate: false,
    });
    setValue("remarks", "", {
      shouldValidate: false,
    });
  };

  const handleSubmitForm = async (formValues: any) => {
    if (accountNo === "") {
      setAccountNoError("Account number is required");
    } else if (selectedType !== 0 && selectedType !== 1) {
      setAccountNoError("");
      setLimitError("Please select limit type");
    } else if (selectedType === 0 && selectedDate === "") {
      setLimitError("");
      setDateError("Please select expiry date");
    } else if (lists.length === 0) {
      setDateError("");
      setApproverError("Please select at least one item from the list");
    } else {
      if (accountName === "") {
        toast.error("Account enquiry failed");
      } else {
        setLimitError("");
        setApproverError("");
        setAccountNoError("");
        const newFormValue = {
          ...formValues,
          accountNo: accountNo,
          limitType: selectedType,
          expiryDate: selectedType === 1 ? "" : selectedDate,
          approvers: lists,
          initiatedDate: initiatedDate,
        };

        try {
          const response = await addlimit(newFormValue).unwrap();
          toast.success(response?.message);
          handleClearFields();
        } catch (error: any) {
          if (error.status === 400) {
            toast.error(error?.data?.title);
          } else {
            toast.error(error?.data?.Message);
          }
        }
      }
    }
  };

  const handleMultiClick = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      approversList?.forEach((list: any) => {
        if (value === list.label) {
          if (!lists.includes(list.value)) {
            setList((prev: any) => [...prev, list.value]);
          }
        }
      });
    } else {
      approversList?.forEach((list: any) => {
        if (value === list.label) {
          setList((prev) => {
            return [...prev.filter((item: any) => item !== list.value)];
          });
        }
      });
    }
  };

  return (
    <div>
      <Header title="Limit Exemption" />
      <Container onSubmit={(e) => e.preventDefault()}>
        <HeaderText> Limit Exemption</HeaderText>
        <FieldWrap>
          <PrimaryInput
            name={"accountNo"}
            type={"text"}
            placeholder={"Account Number"}
            rightIcon={<></>}
            error={accountNoError}
            label={"Account Number"}
            check={""}
            value={accountNo}
            padding={""}
            leftIcon={<ExIcon />}
            onChange={handleFetchAccount}
            // register={register}
            password={false}
          />
          <DropDownC
            value={undefined}
            options={limitList}
            errorMessage={limitError}
            label={"Limit Type"}
            setSelectedItem={setSelectedType}
            placeholder={"Set Expiry Date"}
          />
        </FieldWrap>
        <FieldWrap>
          <PrimaryInput
            name={"accountName"}
            type={"text"}
            placeholder={"Account Name"}
            rightIcon={<></>}
            error={errors.accountNo?.message}
            label={"Account Name"}
            check={""}
            value={accountName}
            disabled={true}
            padding={""}
            leftIcon={<UserIcon />}
            onChange={function (value: any): void {
              throw new Error("Function not implemented.");
            }}
            // register={register}
            password={false}
          />

          <Date
            showModal={false}
            onClosed={function (): void {
              throw new Error("Function not implemented.");
            }}
            isDisabled={selectedType === 1}
            handleDateChange={undefined}
            error={dateError}
            selectDate={setSelectedDate}
            label={"Expiry Date"}
          />
        </FieldWrap>

        <FieldWrap>
          <PrimaryInput
            name={"oneTimeLimit"}
            type={"text"}
            placeholder={"Enter one-time limit"}
            rightIcon={<></>}
            error={errors.oneTimeLimit?.message}
            disabled={undefined}
            label={"One-Time limit "}
            check={""}
            register={register}
            padding={""}
            leftIcon={<ExIcon />}
            value={undefined}
            onChange={function (value: any): void {
              throw new Error("Function not implemented.");
            }}
            password={false}
          />
          <DropDownC
            value={undefined}
            options={approversList}
            label={"Approver"}
            errorMessage={approverError}
            multi={true}
            setSelectedItem={setSelectedApprover}
            placeholder={"Select Approver"}
            list={lists}
            handleMultiClick={handleMultiClick}
          />
        </FieldWrap>

        <FieldWrap>
          <PrimaryInput
            name={"dailyLimit"}
            type={"text"}
            placeholder={"Enter daily limit"}
            rightIcon={<></>}
            error={errors.dailyLimit?.message}
            disabled={undefined}
            label={"Daily Limit"}
            check={""}
            padding={""}
            register={register}
            leftIcon={<ExIcon />}
            value={undefined}
            onChange={function (value: any): void {
              throw new Error("Function not implemented.");
            }}
            password={false}
          />

          <TextArea
            label={"Remark"}
            name={"remarks"}
            register={register}
            padding={0}
            placeholder={"Type a comment"}
            text={""}
            error={errors.remarks?.message}
          />
        </FieldWrap>

        <ButtonWrap>
          <PrimaryButton
            background={"#ffffff"}
            color={"#5c2684"}
            width={150}
            title={"Clear"}
            isLoading={""}
            icon={""}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={handleClearFields}
            leftIcon={false}
            border={"1px solid #5c2684"}
          />
          <PrimaryButton
            background={"#5c2684"}
            color={"#fff"}
            width={150}
            title={"Submit"}
            isLoading={isLoading}
            icon={""}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={handleSubmit(handleSubmitForm)}
            leftIcon={false}
            border={""}
          />
        </ButtonWrap>
      </Container>
    </div>
  );
};

export default LimitExemption;
