import toast from "react-hot-toast";
import { useEffect } from "react";

export const handleLogout = async (logout: any, navigate: any) => {
  try {
    const response = await logout().unwrap();
    if (response?.statusCode === 200) {
      localStorage.clear();
      toast.success(response?.message);
      navigate("/login");
    } else if (response?.statusCode === 401) {
      console.log(response);
      toast.error(response?.message);
    }
  } catch (err: any) {
    console.log(err);
    toast.error(err?.message);
  }
};
