import React, { useEffect, useState } from "react";
import Date from "../../components/Date/Date";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { TestDaa } from "../../utils/config";
import toast from "react-hot-toast";
import {
  Container,
  FieldWrap,
  HeaderText,
  Input,
  InputDiv,
  CloseWrapper,
  GenerateButtonWrap,
  Instruction,
  Status,
  Radio,
  Top,
  TopTitle,
  FieldWrapD,
  DetailsWrap,
  LeftD,
  RightD,
  Text,
  DataDiv,
} from "./style";
import { ReactComponent as UserIcon } from "../../assets/svg/cloud.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import PrimaryButton from "../../components/Buttons/Button";
import PrimaryTable from "../../components/Table";
import GeneralModal from "../../components/Modal/GeneralModal";
import { useSelector } from "react-redux";
import {
  ReportData,
  useGetAllBanksQuery,
  useGetAllInwardMutation,
  useGetInwardMutation,
} from "../../services/limitService";
import PuffLoader from "../../components/Loader";

const Inward = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [selectedItem, setSelectedItem] = useState("");
  const [details, setDetails] = useState<ReportData | undefined>();
  const [selectedType, setSelectedType] = useState("date");
  const [value, setValue] = useState<string>("");
  const [openView, setOpenView] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedBank, setBank] = useState<string>("");
  const [selectedDesBank, setDesBank] = useState<string>("");
  const [inwardContainer, setInwardContainer] = useState<ReportData[]>([]);
  const [inwardList, { isLoading }] = useGetAllInwardMutation();
  const bankList = useGetAllBanksQuery(token);
  const [getInward] = useGetInwardMutation();

  const handleGet = async () => {
    try {
      const response = await inwardList({
        BankCode: selectedItem,
        startDate: selectedStartDate,
        EndDate: selectedEndDate,
        AccountNumber: value,
      }).unwrap();
      setInwardContainer(response.data);
      setSelectedItem("");
      setSelectedStartDate("");
      setSelectedEndDate("");
      setValue("");
    } catch (error: any) {
      toast.error(error?.data?.Message);
      setSelectedItem("");
      setSelectedStartDate("");
      setSelectedEndDate("");
      setValue("");
    }
  };
  useEffect(() => {
    handleGet();
  }, []);

  const newBankList = bankList?.data?.map((item: any) => ({
    id: item.id,
    value: item.code,
    label: item.name,
  }));

  const handleGenerate = () => {
    handleGet();
  };
  const handleModalClose = () => {
    setOpenView(false);
  };
  const header = [
    "Account Name",
    "Source Account",
    "Destination Account",
    "Amount (NGN)",
    "Status",
    "Date",
    "Sender Bank Code",
    "Action",
  ];
  const dataBody = inwardContainer?.map((item: any) => [
    <DataDiv key={item.recordId}>{item?.accountName}</DataDiv>,

    item?.sourceAccountNo,
    item?.destinationAccountNo,
    item?.amount,
    <Status
      key={item.recordId}
      color={item?.status === "Y" ? "#01B272" : "#C43C20"}
      backgroundColor={
        item?.status === "Y"
          ? "rgba(1, 178, 114, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {item?.status === "Y" ? "Yes" : "No"}
    </Status>,
    item?.recordDate?.slice(0, 10),
    item?.senderBankCode,
    <PrimaryButton
      key={item.recordId}
      background={"white"}
      color={"#5c2684"}
      width={""}
      title={"View"}
      loading={false}
      leftIconName={<></>}
      rightIconName={<></>}
      onClick={() => handleView(item?.recordId)}
      border={"1px solid #5c2684"}
    />,
  ]);

  const handleView = async (recordId: string) => {
    const response = await getInward({ recordId: recordId }).unwrap();
    const filterB = newBankList?.find(
      (item: any) => item.value === response?.senderBankCode
    );
    const filterD = newBankList?.find(
      (item: any) => item.value === response?.destinationBankCode
    );
    setBank(filterB?.label);
    setDesBank(filterD?.label);
    setDetails(response);
    setOpenView(true);
  };
  const handleChange = (e: any) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedType(value);
    }
  };

  const handleInputChange = (e: any) => {
    e.preventDefault();
    let value = e.target.value;

    setValue(value);
  };

  return (
    <>
      <Header title="Report" />
      <Container>
        <HeaderText> Inward Request</HeaderText>
        <Instruction>
          Generate report by
          <InputDiv>
            <Radio>
              <Input
                type="radio"
                id="html"
                name="fav_language"
                value="date"
                checked={selectedType === "date"}
                onChange={handleChange}
              />
                <label>Date</label>
            </Radio>
            <Radio>
              <Input
                type="radio"
                id="html"
                name="fav_language"
                value="acc"
                onChange={handleChange}
              />
                <label>Destination Account</label>
            </Radio>
            <Radio>
              <Input
                type="radio"
                id="html"
                name="fav_language"
                value="maker"
                onChange={handleChange}
              />
                <label>Source Bank</label>
            </Radio>
          </InputDiv>
        </Instruction>
        {/* coming back to this */}
        {selectedType === "date" && (
          <FieldWrap>
            <Date
              showModal={false}
              onClosed={function (): void {
                throw new Error("Function not implemented.");
              }}
              value={undefined}
              isDisabled={false}
              handleDateChange={undefined}
              selectDate={setSelectedStartDate}
              label={"Start Date"}
            />
            <Date
              showModal={false}
              onClosed={function (): void {
                throw new Error("Function not implemented.");
              }}
              value={undefined}
              isDisabled={false}
              handleDateChange={undefined}
              selectDate={setSelectedEndDate}
              label={"End Date"}
            />
          </FieldWrap>
        )}
        {selectedType === "acc" && (
          <FieldWrap>
            <PrimaryInput
              name={""}
              type={"text"}
              placeholder={"1234567890"}
              rightIcon={<></>}
              error={""}
              disabled={undefined}
              label={"Account Number"}
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              value={undefined}
              onChange={handleInputChange}
              password={false}
            />
          </FieldWrap>
        )}

        {selectedType === "maker" && (
          <FieldWrap>
            <DropDownC
              value={undefined}
              options={newBankList}
              errorMessage={""}
              label={"Source Bank"}
              setSelectedItem={setSelectedItem}
              placeholder={"Bank"}
            />
          </FieldWrap>
        )}
        {selectedType === "request" && (
          <FieldWrap>
            <DropDownC
              value={undefined}
              options={TestDaa}
              errorMessage={""}
              label={"Request Type"}
              setSelectedItem={setSelectedItem}
              placeholder={"Select request type"}
            />
          </FieldWrap>
        )}

        <GenerateButtonWrap>
          <PrimaryButton
            background={"#5c2684"}
            color={"#fff"}
            width={150}
            title={"Generate"}
            icon={""}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={handleGenerate}
            leftIcon={false}
            border={""}
          />
        </GenerateButtonWrap>

        {isLoading ? (
          <PuffLoader />
        ) : (
          <PrimaryTable header={header} body={dataBody} />
        )}
      </Container>

      {openView && (
        <GeneralModal
          general={true}
          handleModalClose={function (): void {
            throw new Error("Function not implemented.");
          }}
        >
          <Top>
            <TopTitle>Inward Report</TopTitle>
            <CloseWrapper onClick={handleModalClose}>
              <CloseIcon />
            </CloseWrapper>
          </Top>
          <FieldWrapD>
            <DetailsWrap>
              <LeftD>
                <Text>Channel Code</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.channelCode}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Sender's Bank Code</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{selectedBank}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Destination Bank Code</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{selectedDesBank}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Source Account Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.sourceAccountNo}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Originator Name</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.originatorName}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Narration</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.narration}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Original Narration</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.originalNarration}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Amount</Text>{" "}
              </LeftD>
              <RightD>
                <Text>NGN {details?.amount}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Destination Account Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.destinationAccountNo}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Account Name</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.accountName}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Record Date</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.recordDate?.slice(0, 10)}</Text>{" "}
              </RightD>
            </DetailsWrap>
          </FieldWrapD>
        </GeneralModal>
      )}
    </>
  );
};

export default Inward;
