import React, { useEffect, useMemo, useState } from "react";
import Paginator from "../Paginator";
import { v4 as uuidv4 } from "uuid";
import {
  TableBody,
  TableBodyData,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderTitle,
  TablePaginatorWrapper,
  TableWrapper,
} from "./style";

export interface TableCProps {
  header: string[];
  body: any[][] | undefined;
}

const PrimaryTable: React.FC<TableCProps> = ({ header, body }) => {
  const [currentItems, setCurrentItems] = useState<any[][] | undefined>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const dataBody = useMemo(() => {
    return body ?? [];
  }, [body]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(dataBody?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(dataBody?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, dataBody]);

  const handlePageClick = (e: any) => {
    const newOffset = (e.selected * itemsPerPage) % dataBody?.length;
    setItemOffset(newOffset);
  };

  return (
    <TableContainer>
      <TableWrapper>
        <TableHeader>
          <TableHeaderRow>
            {header?.map((title) => (
              <TableHeaderTitle key={title}>{title}</TableHeaderTitle>
            ))}
          </TableHeaderRow>
        </TableHeader>

        <TableBody>
          {currentItems?.map((text) => (
            <TableBodyRow key={uuidv4()}>
              {Object.values(text).map((value) => (
                <TableBodyData key={uuidv4()}>{value}</TableBodyData>
              ))}
            </TableBodyRow>
          ))}
        </TableBody>
      </TableWrapper>

      {dataBody?.length > 10 && (
        <TablePaginatorWrapper>
          <Paginator handlePageClick={handlePageClick} pageCount={pageCount} />
        </TablePaginatorWrapper>
      )}
    </TableContainer>
  );
};

export default PrimaryTable;
