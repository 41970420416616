import React, { useState } from "react";
import {
  Input,
  Show,
  InputContainer,
  ErrorMessage,
  Label,
  CheckWrap,
} from "./style";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import { HiOutlineEyeOff } from "react-icons/hi";

interface InputProps {
  name: string;
  type: string;
  placeholder: string;
  rightIcon: React.ReactElement;
  error: any;
  label: string;
  check: string;
  padding: string | number;
  leftIcon: React.ReactElement;
  value?: any;
  onChange?: (value: any) => void;
  password: boolean;
  disabled?: any;
  defaultValue?: any;
  register?: any;
}
const PrimaryInput: React.FC<InputProps> = ({
  name,
  type,
  placeholder,
  rightIcon,
  error,
  padding,
  label,
  value,
  leftIcon,
  onChange,
  password,
  disabled,
  defaultValue,
  register,
}) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);

  return (
    <>
      <CheckWrap>
        <Label>{label}</Label>

        <InputContainer
          border={active ? "1px solid #784b99" : "1px solid #dfe2e6"}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          background={disabled && "#eeeeee"}
        >
          {leftIcon && <>{leftIcon}</>}
          {register ? (
            <Input
              type={!show ? type || "password" : "text"}
              name={name}
              value={value}
              opacity={disabled && "0.3"}
              placeholder={placeholder}
              padding={padding}
              onChange={onChange}
              disabled={disabled}
              defaultValue={defaultValue}
              {...register(name)}
            />
          ) : (
            <Input
              type={!show ? type || "password" : "text"}
              name={name}
              value={value}
              opacity={disabled && "0.3"}
              placeholder={placeholder}
              padding={padding}
              onChange={onChange}
              disabled={disabled}
              defaultValue={defaultValue}
            />
          )}

          {password && (
            <div onClick={() => setShow(!show)}>
              <Show>{!show ? <EyeIcon /> : <HiOutlineEyeOff size={24} />}</Show>
            </div>
          )}
          {rightIcon && (
            <div onClick={() => setShow(!show)}>
              <Show>{rightIcon}</Show>
            </div>
          )}
        </InputContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </CheckWrap>
    </>
  );
};

export default PrimaryInput;
