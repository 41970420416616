import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";

const BASE_URL = process.env.REACT_APP_BASEURL;

interface PostData {
  username: string;
  role: string;
}
interface UserStruct {
  id: number;
  username: string;
  role: string;
  isActive: boolean;
  lastLogin: string;
  createdOn: string;
  lastModified: string;
  createdBy: string;
}
interface UserData {
  data: UserStruct;
  statusCode?: number;
  message: string;
}

export interface AssignStruct {
  id: number;
  username: string;
  role: string;
  isActive: boolean;
  lastLogin: string;
  createdOn: string;
  lastModified: string;
  createdBy: string;
}
interface AssignResponseData {
  statusCode?: number;
  message?: string;
  data?: AssignStruct;
  error?: string;
}
interface ActionResponse {
  statusCode: number;
  message: string;
  data: boolean;
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;
      const password = (getState() as RootState).StaffDataReducer?.password;
      headers.set("Content-Type", "application/json");
      headers.set("password", password);
      headers.set("accept", "text/plain");
      headers.set("Access-Control-Expose-Headers", "access-token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("Access-Control-Allow-Methods", "*");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //activate user
    activateUser: builder.mutation<ActionResponse, { username: string }>({
      query: (body) => ({
        url: `/user/users/activate`,
        method: "POST",
        body,
      }),
    }),

    //deactivate user
    deactivateUser: builder.mutation<ActionResponse, { username: string }>({
      query: (body) => ({
        url: `/user/users/deactivate`,
        method: "POST",
        body,
      }),
    }),

    //get user
    getUser: builder.mutation<UserData, { username?: string }>({
      query: ({ username }) => ({
        url: `/user/users?username=${username}`,
        method: "GET",
      }),
    }),

    //get all user
    getAllUsers: builder.query<AssignStruct[], void>({
      query: (token) => ({
        url: "user/users",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: { data: AssignStruct[] }, meta, arg) =>
        response.data,
    }),

    //assign role
    assignRole: builder.mutation<AssignResponseData, Partial<PostData>>({
      query: (body) => ({
        url: "/user/add-user",
        method: "POST",
        body,
      }),
    }),

    //get pending user
    pendingUserApproval: builder.mutation<any, void>({
      query: (token) => ({
        url: "user/users-pending-approval",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //approve user
    approveUser: builder.mutation<
      any,
      { username: string; isApproved: boolean }
    >({
      query: (body) => ({
        url: "user/approval-user",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetAllUsersQuery,
  useGetUserMutation,
  useAssignRoleMutation,
  usePendingUserApprovalMutation,
  useApproveUserMutation,
} = adminApi;
