import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";

const BASE_URL = process.env.REACT_APP_BASEURL;

export interface BankPayload {
  channel?: string;
  action?: string;
  bankCode?: string;
  bankId?: string;
}
export interface BankResponse {
  rowId?: number;
  bankCode?: string;
  bankId?: boolean;
}
export interface LimitResponse {
  accountNo: string;
  oneTimeLimit: number;
  dailyLimit: number | null;
  limitType: number | null;
  expiryDate: string;
  approvers?: string;
  isApprover?: boolean;
  remarks: string;
  initiatedBy: string;
  initiatedDate: string;
  approveOn: string;
  limitApprovers?: string;
}
export interface SwitchResponseData {
  requestId?: string;
  bankId?: string;
  bankCode?: string;
  action?: string;
  channel?: string;
  createdBy?: string;
  createdOn?: string;
  approvalFlag?: string;
  approvedOn?: string | null;
  approvedBy?: string;
}
export interface accRes {
  statusCode: number;
  message: string;
  data: string;
  error?: string;
}

interface ApproveLimit {
  accountNo: string;
  value: boolean;
}
interface AddResponse {
  data: string;
  error: string;
  responseCode: string;
  responseDescription: string;
  message: string;
  statusCode?: number;
}

interface UpdateLimit {
  statusCode: number;
  message: string;
  data: null;
}
interface ApproverResponse {
  id: number;
  username: string;
  role: string;
  isActive: boolean;
  lastLogin: string;
  createdOn: string;
  lastModified: string;
  createdBy: string;
}
export interface ReportData {
  recordId?: string;
  channelCode?: string;
  senderBankCode?: string;
  destinationBankCode?: string;
  sourceAccountNo?: string;
  destinationAccountNo?: string;
  accountName?: string;
  destinationInstitutionCode?: string;
  interBankDestinationAccountNo?: string;
  originatorName?: string;
  narration?: string;
  originalNarration?: string;
  paymentReference?: string;
  amount?: string;
  chargeAmount?: string;
  recordDate?: string;
  status?: string;
  tranRemarks?: string;
  tranType?: string;
  reverseDate?: string;
  postedDate?: string;
  fcmbDestinationAccountNo?: string;
  fcmbSourceAccountNo?: string;
}

export interface ReportResponse {
  statusCode: number;
  message: string;
  data: ReportData[];
}

interface BankList {
  id: string;
  name: string;
  code: string;
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;
      const password = (getState() as RootState).StaffDataReducer?.password;
      headers.set("Content-Type", "application/json");
      headers.set("password", password);
      headers.set("accept", "text/plain");
      headers.set("Access-Control-Expose-Headers", "access-token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("Access-Control-Allow-Methods", "*");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const limitApi = createApi({
  reducerPath: "limitApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //get all banks
    getSwitchBanks: builder.mutation<any, { channel: string; token: string }>({
      query: ({ token, channel }) => ({
        url: `switch/banks/${channel}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
    }),

    //modify channel
    modifyChannel: builder.mutation<AddResponse, Partial<BankPayload>>({
      query: (body) => ({
        url: "switch/modify-channel",
        method: "POST",
        body,
      }),
    }),

    //get all limits
    getSwitchApproval: builder.query<SwitchResponseData[], void>({
      query: (token) => ({
        url: "switch/awaiting-approval",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (
        response: { data: SwitchResponseData[] },
        meta,
        arg
      ) => response.data,
    }),

    //get all limits
    getAllLimits: builder.query<LimitResponse[], void>({
      query: (token) => ({
        url: "limit/awaiting-approval",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: { data: LimitResponse[] }, meta, arg) =>
        response.data,
    }),

    //get all limits
    getLimitByAccNo: builder.mutation<
      LimitResponse[],
      { accountNo: string; token: any }
    >({
      query: ({ token, accountNo }) => ({
        url: `limit/awaiting-approval/${accountNo}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
      transformResponse: (response: { data: LimitResponse[] }, meta, arg) =>
        response.data,
    }),

    //get a limit
    getLimit: builder.mutation<LimitResponse, { id: number }>({
      query: ({ id }) => ({
        url: `limit/${id}`,
        method: "GET",
      }),
      transformResponse: (response: { data: LimitResponse }, meta, arg) =>
        response.data,
    }),

    //get an inward report
    getInward: builder.mutation<ReportData, { recordId: string }>({
      query: ({ recordId }) => ({
        url: `report/inward/${recordId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: ReportData }, meta, arg) =>
        response.data,
    }),

    //get an outward report
    getOutward: builder.mutation<ReportData, { recordId: string }>({
      query: ({ recordId }) => ({
        url: `report/outward/${recordId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: ReportData }, meta, arg) =>
        response.data,
    }),

    //get an outward report
    getSwitchReport: builder.mutation<
      any,
      {
        startDate: string;
        endDate: string;
        pageSize: number;
        pageNumber: number;
      }
    >({
      query: ({ startDate, endDate, pageSize, pageNumber }) => ({
        url: startDate
          ? `report/bank-switch?StartDate=${startDate}&EndDate=${endDate}&PageSize=${pageSize}&PageNumber=${pageNumber}`
          : `report/bank-switch?PageSize=${pageSize}&PageNumber=${pageNumber}`,
        method: "GET",
      }),
      transformResponse: (response: { data: ReportData }, meta, arg) =>
        response.data,
    }),

    //get a limit by account number
    getLimitByAccount: builder.mutation<
      LimitResponse,
      { accountNumber: string }
    >({
      query: ({ accountNumber }) => ({
        url: `limit/get-by-account-number/${accountNumber}`,
        method: "GET",
      }),
      transformResponse: (response: { data: LimitResponse }, meta, arg) =>
        response.data,
    }),

    //get all approvers
    getAllApprovers: builder.query<ApproverResponse[], void>({
      query: (token) => ({
        url: "/user/approvers",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: { data: ApproverResponse[] }, meta, arg) =>
        response.data,
    }),

    //add a limit
    addLimit: builder.mutation<AddResponse, Partial<LimitResponse>>({
      query: (body) => ({
        url: "limit/create",
        method: "POST",
        body,
      }),
    }),

    //update a limit
    updateLimit: builder.mutation<AddResponse, Partial<LimitResponse>>({
      query: ({ accountNo, ...body }) => ({
        url: `limit/update/${accountNo}`,
        method: "PUT",
        body,
      }),
    }),

    //approve a limit
    approveLimit: builder.mutation<UpdateLimit, Partial<ApproveLimit>>({
      query: ({ accountNo, value }) => ({
        url: `limit/approve-or-reject/${accountNo}?isApproved=${value}`,
        method: "PUT",
      }),
    }),

    //approve a switch
    approveSwitch: builder.mutation<
      UpdateLimit,
      Partial<{ requestId: string; isApproved: boolean }>
    >({
      query: ({ requestId, isApproved }) => ({
        url: `switch/approve-request/${requestId}?isApproved=${isApproved}`,
        method: "GET",
      }),
    }),

    //get all inward
    getAllInward: builder.mutation<
      ReportResponse,
      {
        BankCode: string;
        startDate: string;
        EndDate: string;
        AccountNumber: string;
      }
    >({
      query: ({ BankCode, startDate, EndDate, AccountNumber }) => ({
        url: `/report/inward?BankCode=${BankCode}&AccountNumber=${AccountNumber}&StartDate=${startDate}&EndDate=${EndDate}`,
        method: "GET",
      }),
    }),

    //get all outward
    getAllOutward: builder.mutation<
      ReportResponse,
      {
        BankCode: string;
        startDate: string;
        EndDate: string;
        AccountNumber: string;
      }
    >({
      query: ({ BankCode, startDate, EndDate, AccountNumber }) => ({
        url: `/report/outward?BankCode=${BankCode}&AccountNumber=${AccountNumber}&StartDate=${startDate}&EndDate=${EndDate}`,
        method: "GET",
      }),
    }),

    //get all limit
    getAllLimit: builder.mutation<
      ReportResponse,
      {
        startDate: string;
        endDate: string;
      }
    >({
      query: ({ startDate, endDate }) => ({
        url: !startDate
          ? "/report/limits"
          : `/report/limits?StartDate=${startDate}&EndDate=${endDate}`,
        method: "GET",
      }),
    }),
    //get all outward
    getAllBanks: builder.query<BankList[], void>({
      query: (token) => ({
        url: "/report/bank-list",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: { data: BankList[] }, meta, arg) =>
        response.data,
    }),

    //fetch account
    getAccDetails: builder.mutation<
      accRes,
      { accountNo?: string; token: string }
    >({
      query: ({ token, accountNo }) => ({
        url: `/limit/enquiry/${accountNo}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
      // transformResponse: (response: { data: siList[] }, meta, arg) =>
      //   response.data,
    }),

    logout: builder.mutation<any, any>({
      query: () => ({
        url: "/user/logout",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetAllLimitsQuery,
  useGetLimitMutation,
  useGetAllApproversQuery,
  useAddLimitMutation,
  useUpdateLimitMutation,
  useApproveLimitMutation,
  useGetAllInwardMutation,
  useGetLimitByAccountMutation,
  useGetAllOutwardMutation,
  useGetAllBanksQuery,
  useGetInwardMutation,
  useGetOutwardMutation,
  useGetLimitByAccNoMutation,
  useGetAccDetailsMutation,
  useGetAllLimitMutation,
  useGetSwitchApprovalQuery,
  useApproveSwitchMutation,
  useLogoutMutation,
  useGetSwitchBanksMutation,
  useModifyChannelMutation,
  useGetSwitchReportMutation,
} = limitApi;
